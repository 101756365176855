// import React, { useContext, useState, useEffect } from "react";
// import styled from "styled-components";
// import { Modal } from "react-bootstrap";
// import GlobalContext from "../../context/GlobalContext";
// import apiClient from '../../axiosConfig';

// const ModalStyled = styled(Modal)`
//   /* &.modal {
//     z-index: 10050;
//   } */
// `;

// const ModalSignUp = (props) => {
//   const [showPassFirst, setShowPassFirst] = useState(true);
//   const [showPassSecond, setShowPassSecond] = useState(true);

//   const gContext = useContext(GlobalContext);
//   const handleClose = () => {
//     gContext.toggleSignUpModal();
//   };

//   const togglePasswordFirst = () => {
//     setShowPassFirst(!showPassFirst);
//   };

//   const togglePasswordSecond = () => {
//     setShowPassSecond(!showPassSecond);
//   };

//   const [formData, setFormData] = useState({
//     phone: '',
//     role: '',
//     email: '',
//     password: '',
//     confirmPassword: '',
//   });

//   const [roleData, setRoleData] = useState([]);

//   useEffect(() => {
//     console.log('Fetching roles');
//     apiClient.get('role')
//       .then(response => {
//         if (response.data.success) {
//           setRoleData(response.data.data);
//         } else {
//           console.error('Failed to fetch role data');
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching role data:', error);
//       });
//   }, []);

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (formData.password !== formData.confirmPassword) {
//       alert("Passwords do not match");
//       return;
//     }

//     apiClient.post('webregister', formData)
//       .then(response => {
//         console.log('User created successfully:', response.data);
//         handleClose();
//       })
//       .catch(error => {
//         console.error('There was an error creating the user:', error);
//       });
//   };

//   return (
//     <ModalStyled
//       {...props}
//       size="lg"
//       centered
//       show={gContext.signUpModalVisible}
//       onHide={gContext.toggleSignUpModal}
//     >
//       <Modal.Body className="p-0">
//         <button
//           type="button"
//           className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
//           onClick={handleClose}
//         >
//           <i className="fas fa-times"></i>
//         </button>
//         <div className="login-modal-main bg-white rounded-8 overflow-hidden">
//           <div className="row no-gutters">
//             <div className="col-lg-5 col-md-6">
//               <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
//                 <div className="pb-9">
//                   <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
//                     Create a free account today
//                   </h3>
//                   <p className="mb-0 font-size-4 text-white">
//                     Create your account to continue and explore new jobs.
//                   </p>
//                 </div>
//                 <div className="border-top border-default-color-2 mt-auto">
//                   <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
//                     <div className="pt-5 px-9">
//                       <h3 className="font-size-7 text-white">295</h3>
//                       <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
//                         New jobs posted today
//                       </p>
//                     </div>
//                     <div className="pt-5 px-9">
//                       <h3 className="font-size-7 text-white">14</h3>
//                       <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
//                         New companies registered
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-6">
//               <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
//                 <div className="row">
//                   <div className="col-4 col-xs-12">
//                     <a
//                       href="/#"
//                       className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
//                     >
//                       <i className="fab fa-linkedin pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
//                       <span className="d-none d-xs-block">
//                         Import from LinkedIn
//                       </span>
//                     </a>
//                   </div>
//                   <div className="col-4 col-xs-12">
//                     <a
//                       href="/#"
//                       className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
//                     >
//                       <i className="fab fa-google pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
//                       <span className="d-none d-xs-block">
//                         Import from Google
//                       </span>
//                     </a>
//                   </div>
//                   <div className="col-4 col-xs-12">
//                     <a
//                       href="/#"
//                       className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
//                     >
//                       <i className="fab fa-facebook-square pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
//                       <span className="d-none d-xs-block">
//                         Import from Facebook
//                       </span>
//                     </a>
//                   </div>
//                 </div>
//                 <div className="or-devider">
//                   <span className="font-size-3 line-height-reset">Or</span>
//                 </div>
//                 <form onSubmit={handleSubmit}>
//                   <div className="form-group">
//                     <label
//                       htmlFor="name"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="name"
//                       id="name"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                     />
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="phone"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Phone
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="phone"
//                       id="phone"
//                       name="username"
//                       value={formData.username}
//                       onChange={handleChange}
//                     />
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="role"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Role:
//                     </label>
//                     <select
//                       id="role"
//                       name="role"
//                       className="form-control"
//                       value={formData.role}
//                       onChange={handleChange}
//                     >
//                       <option value="">Select a role</option>
//                       {roleData.length > 0 && roleData.map((role) => (
//                         <option key={role.id} value={role.id}>
//                           {role.name}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                   <div className="form-group">
//                     <label
//                       htmlFor="email2"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       E-mail
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       placeholder="example@gmail.com"
//                       id="email2"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label
//                       htmlFor="password"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Password
//                     </label>
//                     <div className="position-relative">
//                       <input
//                         type={showPassFirst ? "password" : "text"}
//                         className="form-control"
//                         id="password"
//                         placeholder="Enter password"
//                         name="password"
//                         value={formData.password}
//                         onChange={handleChange}
//                       />
//                       <a
//                         href="/#"
//                         className="show-password pos-abs-cr fas mr-6 text-black-2"
//                         onClick={(e) => {
//                           e.preventDefault();
//                           togglePasswordFirst();
//                         }}
//                       >
//                         <span className="d-none">none</span>
//                       </a>
//                     </div>
//                   </div>
//                   <div className="form-group">
//                     <label
//                       htmlFor="password2"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Confirm Password
//                     </label>
//                     <div className="position-relative">
//                       <input
//                         type={showPassSecond ? "password" : "text"}
//                         className="form-control"
//                         id="password2"
//                         placeholder="Enter password"
//                         name="confirmPassword"
//                         value={formData.confirmPassword}
//                         onChange={handleChange}
//                       />
//                       <a
//                         href="/#"
//                         className="show-password pos-abs-cr fas mr-6 text-black-2"
//                         onClick={(e) => {
//                           e.preventDefault();
//                           togglePasswordSecond();
//                         }}
//                       >
//                         <span className="d-none">none</span>
//                       </a>
//                     </div>
//                   </div>
//                   <div className="form-group d-flex flex-wrap justify-content-between mb-1">
//                     <label
//                       htmlFor="terms-check2"
//                       className="gr-check-input d-flex  mr-3"
//                     >
//                       <input
//                         className="d-none"
//                         type="checkbox"
//                         id="terms-check2"
//                       />
//                       <span className="checkbox mr-5"></span>
//                       <span className="font-size-3 mb-0 line-height-reset d-block">
//                         Agree to the{" "}
//                         <a href="/#" className="text-primary">
//                           Terms & Conditions
//                         </a>
//                       </span>
//                     </label>
//                     <a
//                       href="/#"
//                       className="font-size-3 text-dodger line-height-reset"
//                     >
//                       Forget Password
//                     </a>
//                   </div>
//                   <div className="form-group mb-8">
//                     <button
//                       type="submit"
//                       className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
//                     >
//                       Sign Up
//                     </button>
//                   </div>
//                   <p className="font-size-4 text-center heading-default-color">
//                     Don’t have an account?{" "}
//                     <a href="/#" className="text-primary">
//                       Create a free account
//                     </a>
//                   </p>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//     </ModalStyled>
//   );
// };

// export default ModalSignUp;


import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import apiClient from '../../axiosConfig';
import axios from "axios";
import network from "../../../constants/Network";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalSignUp = (props) => {
  const [showPassFirst, setShowPassFirst] = useState(false);
  const [showPassSecond, setShowPassSecond] = useState(false);
  const [errors, setErrors] = useState({});
  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);

  const [roles, setRoles] = useState([]);
  const [pickers, setPickers] = useState([]);
  // const [selectedRole, setSelectedRole] = useState(null);
  // const [selectedSubRole, setSelectedSubRole] = useState(null);
  const [anyMoreSubrole, setAnyMoreSubrole] = useState(true);

  const [formData, setFormData] = useState({
    name: '',
    registration_number: '',
    state_of_registration: '',
    mobile_number: '',
    email: '',
    password: '',
    password_confirmation: '',
    role: '',
  });

  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const gContext = useContext(GlobalContext);

  const handleClose = () => {
    console.log("Resetting form data and closing modal");
    setErrors({});
    setFormData({
      name: '',
      registration_number: '',
      state_of_registration: '',
      mobile_number: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: '',
    });
    setPickers([{ id: null, subRoles: roles.filter(role => role.parent_slug === 'job-seeker') }]);
    setAnyMoreSubrole(true);
    gContext.toggleSignUpModal();
  };

  const handleSignInLink = () => {
    console.log("Resetting form data and opening sign in modal");
    setErrors({});
    setFormData({
      name: '',
      registration_number: '',
      state_of_registration: '',
      mobile_number: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: '',
    });
    setPickers([{ id: null, subRoles: roles.filter(role => role.parent_slug === 'job-seeker') }]);
    setAnyMoreSubrole(true);
    gContext.toggleSignUpModal();
    gContext.toggleSignInModal();
  };

  const togglePasswordFirst = () => {
    setShowPassFirst(!showPassFirst);
  };

  const togglePasswordSecond = () => {
    setShowPassSecond(!showPassSecond);
  };

  const fetchJobSeekeerRoles = async () => {
    try {
      const response = await apiClient.get('/roles/job-seeker-web');
      if (response.data.success && isMountedRef.current) {
        setRoles(response.data.data);
        setPickers([{ id: null, subRoles: response.data.data.filter(role => role.parent_slug === 'job-seeker') }]);
        console.log('Fetched job seeker roles:', response.data.data);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setRoles([]);
        }
        console.error('Failed to fetch job seeker roles');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setRoles([]);
      }
      console.error('Error fetching job seeker roles:', error);
    }
  };



  // the below is the code for selection of role field

  const handleRoleChange = (roleId, index) => {

    setAnyMoreSubrole(false);

    const newPickers = [...pickers];
    newPickers[index].id = roleId;

    setFormData(prevData => ({
      ...prevData,
      role: roleId,
    }));

    const nextLevelSubRoles = roles.filter(role => role.parent_id === parseInt(roleId));

    if (nextLevelSubRoles.length > 0) {

      newPickers.splice(index + 1);

      newPickers.push({ id: null, subRoles: nextLevelSubRoles });
      // setSelectedRole(newPickers[0].id);
      // setSelectedSubRole(roleId);
      setAnyMoreSubrole(true);
    } else {

      newPickers.splice(index + 1);
      // setSelectedRole(newPickers[0].id);
      // setSelectedSubRole(roleId);

      setErrors(prevErrors => ({
        ...prevErrors,
        role: ''
      }));

    }

    setPickers(newPickers);
  };

  // the the code for selection of role field till here



  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    let isValid = true;

    if (!formData.name) {
      setErrors(prevErrors => ({
        ...prevErrors,
        name: 'Full name is required'
      }));
      isValid = false;
    }

    if (!formData.registration_number) {
      setErrors(prevErrors => ({
        ...prevErrors,
        registration_number: 'Registraton number is is required'
      }));
      isValid = false;
    }

    if (!formData.state_of_registration) {
      setErrors(prevErrors => ({
        ...prevErrors,
        state_of_registration: 'State of registration is required'
      }));
      isValid = false;
    }

    const mobileRegex = /^\d{10}$/;
    if (!formData.mobile_number) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mobile_number: "Mobile number is required"
      }));
      isValid = false;
    } else if (!mobileRegex.test(formData.mobile_number)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mobile_number: "Enter a valid mobile number"
      }));
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Email is required'
      }));
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Enter a valid email address'
      }));
      isValid = false;
    }

    if (!formData.password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'Password is required'
      }));
      isValid = false;
    }

    if (formData.password !== formData.password_confirmation) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'Passwords do not match',
        password_confirmation: 'Passwords do not match'
      }));
      isValid = false;
    }

    if (!formData.role || anyMoreSubrole) {
      setErrors(prevErrors => ({
        ...prevErrors,
        role: 'Select a role'
      }));
      isValid = false;
    }

    if (!isAgreedToTerms) {
      setErrors(prevErrors => ({
        ...prevErrors,
        isAgreedToTerms: 'Please accept the terms of service and privacy policy'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    // console.log('This is the formData:', formData);

    try {
      const response = await apiClient.post('user-profile-register-web', formData);
      console.log('This is the response:', response);

      if (response.data.message) {
        console.log('User profile created successfully');
        gContext.setUserName(formData.mobile_number);
        setFormData({
          name: '',
          registration_number: '',
          state_of_registration: '',
          mobile_number: '',
          email: '',
          password: '',
          password_confirmation: '',
          role: '',
        });
        setErrors({});
        setPickers([{ id: null, subRoles: roles.filter(role => role.parent_slug === 'job-seeker') }]);
        setAnyMoreSubrole(true);
        gContext.toggleSignUpModal();
        gContext.toggleMobNumVerifyModal();
      }

    } catch (error) {
      console.error('Error creating user profile:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            signUpError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          signUpError: 'Something went wrong, please try again',
        }));
      }
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobSeekeerRoles();
    };

    fetchData();
  }, []);

  const styles = ModalSignUpStyling();

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.signUpModalVisible}
      onHide={gContext.toggleSignUpModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white bg-newcolor rounded-8 overflow-hidden">
          <div className="row no-gutters">
            <div className="col-lg-5 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                  <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Create a free account today
                  </h3>
                  <p className="mb-0 font-size-4 text-white">
                    Explore career opportunities tailored to your skills and aspirations!
                  </p>
                </div>
                <div className="border-top border-default-color-2 mt-auto">
                  <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{gContext.newJobsToday}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New jobs posted today
                      </p>
                    </div>
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{gContext.newCompaniesToday}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New companies registered
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="bg-newbg h-100 px-11 pt-11 pb-7">
                {/* <div className="row">
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-linkedin pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Import from LinkedIn
                      </span>
                    </a>
                  </div>
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-google pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Import from Google
                      </span>
                    </a>
                  </div>
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-facebook-square pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Import from Facebook
                      </span>
                    </a>
                  </div>
                </div>
                <div className="or-devider">
                  <span className="font-size-3 line-height-reset">Or</span>
                </div> */}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label
                      htmlFor="name"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your first name"
                      id="firstname"
                      name="firstname"
                      value={formData.name}
                      onChange={handleChange}  // This doesn't work in all cases

                    />
					<input
                      type="text"
                      className="form-control"
                      placeholder="Enter your last name"
                      id="lastname"
                      name="lastname"
                      value={formData.name}
                      onChange={handleChange} style={{ marginTop: "10px" }} 
                    />
                    {errors?.name ? <p className="font-size-3 text-danger">
                      {errors.name}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="registration_number"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Registration Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your registration number"
                      id="registration_number"
                      name="registration_number"
                      value={formData.registration_number}
                      onChange={handleChange}
                    />
                    {errors?.registration_number ? <p className="font-size-3 text-danger">
                      {errors.registration_number}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="state_of_registration"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      State of Registration
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your state of registration"
                      id="state_of_registration"
                      name="state_of_registration"
                      value={formData.state_of_registration}
                      onChange={handleChange}
                    />
                    {errors?.state_of_registration ? <p className="font-size-3 text-danger">
                      {errors.state_of_registration}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="mobile_number"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your mobile number"
                      id="mobile_number"
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleChange}
                    />
                    {errors?.mobile_number ? <p className="font-size-3 text-danger">
                      {errors.mobile_number}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="email"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="example@gmail.com"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors?.email ? <p className="font-size-3 text-danger">
                      {errors.email}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPassFirst ? "text" : "password"}
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <a
                        href="/#"
                        className="pos-abs-cr mr-6 text-black-2"
                        onClick={(e) => {
                          e.preventDefault();
                          togglePasswordFirst();
                        }}
                      >
                        <i className={`fas ${showPassFirst ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </a>
                    </div>
                    {errors?.password ? <p className="font-size-3 text-danger">
                      {errors.password}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="password_confirmation"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Confirm Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPassSecond ? "text" : "password"}
                        className="form-control"
                        id="password_confirmation"
                        placeholder="Confirm password"
                        name="password_confirmation"
                        value={formData.password_confirmation}
                        onChange={handleChange}
                      />
                      <a
                        href="/#"
                        className="pos-abs-cr mr-6 text-black-2"
                        onClick={(e) => {
                          e.preventDefault();
                          togglePasswordSecond();
                        }}
                      >
                        <i className={`fas ${showPassSecond ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </a>
                    </div>
                    {errors?.password_confirmation ? <p className="font-size-3 text-danger">
                      {errors.password_confirmation}
                    </p> : null}
                  </div>

                  {/* <div className="form-group">
                    <label
                      htmlFor="role"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Role:
                    </label>
                    <select
                      id="role"
                      name="role"
                      className="form-control"
                      value={formData.role}
                      onChange={handleChange}
                    >
                      <option value="">Select a role</option>
                      {initialRoles.length > 0 && initialRoles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                    {errors?.role ? <p className="font-size-3 text-danger">
                      {errors.role}
                    </p> : null}
                  </div> */}

                  {(roles.length !== 0) ? (
                    <div>
                      {pickers.map((picker, index) => (
                        <div key={index} className="form-group">
                          <label
                            htmlFor={`role-${index}`}
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            {index === 0 ? 'Role' : 'Sub-Role'}
                          </label>
                          <select
                            id={`role-${index}`}
                            name={`role-${index}`}
                            className="form-control"
                            value={picker.id || ''}
                            onChange={(event) => handleRoleChange(event.target.value, index)}
                          >
                            <option value="">Select a role</option>
                            {picker.subRoles.map(role => (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                      {errors?.role ? <p className="font-size-3 text-danger">
                        {errors.role}
                      </p> : null}
                    </div>
                  ) : (
                    <div className="form-group">
                      <p className="font-size-4 text-black-2 font-weight-semibold line-height-reset">Role: Please wait...</p>
                      {errors?.role ? <p className="font-size-3 text-danger">
                        {errors.role}
                      </p> : null}
                    </div>
                  )}

                  <div className="form-group d-flex flex-wrap justify-content-between">
                    <label
                      htmlFor="terms-check2"
                      className="gr-check-input d-flex  mr-3"
                    >
                      <input
                        className="d-none"
                        type="checkbox"
                        id="terms-check2"
                        checked={isAgreedToTerms}
                        onChange={(e) => setIsAgreedToTerms(e.target.checked)}
                      />
                      <span className="checkbox mr-5"></span>
                      <span className="font-size-3 line-height-reset d-block">
                        Agree to the{" "}
                        <a href="/#" className="text-primary">
                          Terms & Conditions
                        </a>
                      </span>
                    </label>
                    <a
                      href="/#"
                      className="font-size-3 text-dodger line-height-reset"
                    >
                      Forget Password
                    </a>
                    {errors?.isAgreedToTerms ? <p className="font-size-3 text-danger">
                      {errors.isAgreedToTerms}
                    </p> : null}
                  </div>

                  <div className="form-group mb-8 mt-1">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                        >
                          Sign Up
                        </button>
                      </div>
                      <div className="col-12 d-flex justify-content-center">
                        {errors?.signUpError ? (
                          <p className="font-size-3 text-danger text-center">
                            {errors.signUpError}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <p className="font-size-4 text-center heading-default-color">
                    Already a Member?{" "}
                    <button
                      type="button"
                      onClick={handleSignInLink}
                      className="text-primary"
                      style={styles.btnAsText}>
                      Login
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalSignUp;

function ModalSignUpStyling() {
  const styles = {
    btnAsText: {
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      color: 'inherit',
      font: 'inherit',
      cursor: 'pointer',
    }
  }
  return styles;
}
