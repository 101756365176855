// import React, { useState } from "react";

// const GlobalContext = React.createContext();

// const GlobalProvider = ({ children }) => {
//   const [themeDark, setThemeDark] = useState(false);
//   const [showSidebarDashboard, setShowSidebarDashboard] = useState(true);
//   const [applicationModalVisible, setApplicationModalVisible] = useState(false);
//   const [signInModalVisible, setSignInModalVisible] = useState(false);
//   const [signUpModalVisible, setSignUpModalVisible] = useState(false);
//   const [videoModalVisible, setVideoModalVisible] = useState(false);
//   const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
//   const [user, setUser] = useState(null); // Track the user state
//   const [header, setHeader] = useState({
//     theme: "light",
//     bgClass: "default",
//     variant: "primary",
//     align: "left",
//     isFluid: false,
//     button: "cta", // profile, account, null
//     buttonText: "Get started free", // profile, account, null
//     reveal: true,
//   });
//   const [footer, setFooter] = useState({
//     theme: "dark",
//     style: "style1", //style1, style2
//   });

//   const toggleTheme = () => {
//     setThemeDark(!themeDark);
//   };

//   const toggleSidebarDashboard = () => {
//     setShowSidebarDashboard(!showSidebarDashboard);
//   };

//   const toggleVideoModal = () => {
//     setVideoModalVisible(!videoModalVisible);
//   };

//   const toggleApplicationModal = () => {
//     setApplicationModalVisible(!applicationModalVisible);
//   };

//   const toggleSignInModal = () => {
//     setSignInModalVisible(!signInModalVisible);
//   };

//   const toggleSignUpModal = () => {
//     setSignUpModalVisible(!signUpModalVisible);
//   };

//   const toggleOffCanvas = () => {
//     setVisibleOffCanvas(!visibleOffCanvas);
//   };

//   const closeOffCanvas = () => {
//     setVisibleOffCanvas(false);
//   };

//   return (
//     <GlobalContext.Provider
//       value={{
//         themeDark,
//         toggleTheme,
//         showSidebarDashboard,
//         toggleSidebarDashboard,
//         videoModalVisible,
//         toggleVideoModal,
//         applicationModalVisible,
//         toggleApplicationModal,
//         signInModalVisible,
//         toggleSignInModal,
//         signUpModalVisible,
//         toggleSignUpModal,
//         visibleOffCanvas,
//         toggleOffCanvas,
//         closeOffCanvas,
//         header,
//         setHeader,
//         footer,
//         setFooter,
//         user, // Provide user state
//         setUser, // Provide function to update user state
//       }}
//     >
//       {children}
//     </GlobalContext.Provider>
//   );
// };

// export default GlobalContext;
// export { GlobalProvider };


import React, { useEffect, useRef, useState } from "react";
import Cookies from 'js-cookie';
import network from "../../constants/Network";
import axios from "axios";

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false);
  const [showSidebarDashboard, setShowSidebarDashboard] = useState(true);
  const [applicationModalVisible, setApplicationModalVisible] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [otpVerifyModalVisible, setOtpVerifyModalVisible] = useState(false);
  const [mobNumVerifyModalVisible, setMobNumVerifyModalVisible] = useState(false);
  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [hrSignUpModalVisible, setHrSignUpModalVisible] = useState(false);
  const [signUpSelectionModalVisible, setSignUpSelectionModalVisible] = useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
  const [emailUpdateModalVisible, setEmailUpdateModalVisible] = useState(false);
  const [emailVerifyModalVisible, setEmailVerifyModalVisible] = useState(false);
  const [photoUpdateModalVisible, setPhotoUpdateModalVisible] = useState(false);
  const [passwordUpdateModalVisible, setPasswordUpdateModalVisible] = useState(false);
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  const [reportJobModalVisible, setReportJobModalVisible] = useState(false);
  const [reportProfileModalVisible, setReportProfileModalVisible] = useState(false);
  const [locationUpdateModalVisible, setLocationUpdateModalVisible] = useState(false);

  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
  const [userName, setUserName] = useState('');

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState(null);
  const [isJobProvider, setIsJobProvider] = useState(0);
  const [mobileNumberVisible, setMobileNumberVisible] = useState(0);
  const [profileImageVisible, setProfileImageVisible] = useState(0);

  const isMountedRef = useRef(false);
  const [newJobsToday, setNewJobsToday] = useState(0);
  const [newCompaniesToday, setNewCompaniesToday] = useState(0);
  const [jobApplicantsCount, setJobApplicantsCount] = useState(0);

  const [isChatVisible, setChatVisible] = useState(true);
  const handleClose = () => setChatVisible(false);
  const handleOpenChat = () => setChatVisible(true);

  const [jobProviderId, setJobProviderId] = useState(null);
  const [jobSeekerId, setJobSeekerId] = useState(null);

  const [reportJobId, setReportJobId] = useState(null);
  const [reportProfileId, setReportProfileId] = useState(null);
  const [applicationId, setApplicationId] = useState(null);
  const [imageToDisplay, setImageToDisplay] = useState('');
  const [imageViewModalVisible, setImageViewModalVisible] = useState(false);

  const getUserCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          console.log('Fetched user coordinates:', `lat: ${position.coords.latitude}, lng: ${position.coords.longitude}`);
        },
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const fetchJobsCompaniesCount = async () => {
    try {
      const response = await axios.get(`${network.serverip}/api/jobs-and-companies-count-web`);
      if (response.data.success) {
        setNewJobsToday(response.data.newJobsToday);
        setNewCompaniesToday(response.data.newCompaniesToday);
        console.log('New jobs today:', response.data.newJobsToday);
        console.log('New companies today:', response.data.newCompaniesToday);
      } else if (!response.data.success) {
        console.error('Failed to fetch job and companies count');
      }
    } catch (error) {
      console.error('Error fetching job and companies count:', error);
    }
  };

  const fetchJobApplicantsCount = async () => {
    try {

      if (!user || !user.id) {
        console.error('User or user ID is not available');
        return;
      }

      const userId = user.id;

      const response = await axios.post(`${network.serverip}/api/job-applicants-count-web`, {
        userId: userId,
      });

      if (response.data.success) {
        setJobApplicantsCount(response.data.jobApplicantsCount);
        console.log('Job applicants count:', response.data.jobApplicantsCount);
      } else if (!response.data.success) {
        console.error('Failed to fetch job applicants count');
      }
    } catch (error) {
      console.error('Error fetching job applicants count:', error);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    const fetchData = async () => {
      getUserCoordinates();
      await fetchJobsCompaniesCount();
      await fetchJobApplicantsCount();
    };

    fetchData();
    return () => {
      isMountedRef.current = false;
    };
  }, [user]);

  useEffect(() => {

    // Initialize user data from cookies
    const storedToken = Cookies.get('token');
    const storedUser = Cookies.get('user'); // Will be a JSON string or undefined
    const storedProfile = Cookies.get('profile'); // Will be a JSON string or undefined
    const storedIsJobProvider = Cookies.get('isJobProvider'); // Can be a string
    const storedMobileNumberVisible = Cookies.get('mobileNumberVisible');
    const storedProfileImageVisible = Cookies.get('profileImageVisible');

    if (storedToken) {
      setToken(storedToken);
      console.log("Token in gContext:", storedToken);
    }
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      console.log("User in gContext:", parsedUser);
    }
    if (storedProfile) {
      const parsedProfile = JSON.parse(storedProfile);
      setProfile(parsedProfile);
      console.log("Profile in gContext:", parsedProfile);
    }
    if (storedIsJobProvider) {
      // Convert string to number explicitly
      const isProvider = Number(storedIsJobProvider);
      setIsJobProvider(isProvider);
      console.log("Is Job Provider in gContext (as number):", isProvider);
    }
    if (storedMobileNumberVisible) {
      // Convert string to number explicitly
      const isMobNumVisible = Number(storedMobileNumberVisible);
      setMobileNumberVisible(isMobNumVisible);
      console.log("Mobile number visible in gContext (as number):", isMobNumVisible);
    }
    if (storedProfileImageVisible) {
      // Convert string to number explicitly
      const imageVisible = Number(storedProfileImageVisible);
      setProfileImageVisible(imageVisible);
      console.log("Profile image visible in gContext (as number):", imageVisible);
    }
  }, []);

  const [header, setHeader] = useState({
    theme: "light",
    bgClass: "default",
    variant: "primary",
    align: "left",
    isFluid: false,
    button: "cta", // profile, account, null
    buttonText: "Get started free", // profile, account, null
    reveal: true,
  });
  const [footer, setFooter] = useState({
    theme: "dark",
    style: "style1", //style1, style2
  });

  const toggleTheme = () => {
    setThemeDark(!themeDark);
  };


  const toggleSidebarDashboard = () => {
    setShowSidebarDashboard(!showSidebarDashboard);
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleApplicationModal = (id) => {
    setApplicationModalVisible(!applicationModalVisible);
    if (!id) {
        setApplicationId(null);
    } else {
      setApplicationId(id);
    }
  };

  const toggleSignInModal = () => {
    setSignInModalVisible(!signInModalVisible);
  };

  const toggleSignUpModal = () => {
    setSignUpModalVisible(!signUpModalVisible);
  };

  const toggleOtpVerifyModal = () => {
    setOtpVerifyModalVisible(!otpVerifyModalVisible);
  };

  const toggleMobNumVerifyModal = () => {
    setMobNumVerifyModalVisible(!mobNumVerifyModalVisible);
  };

  const toggleHrSignUpModal = () => {
    setHrSignUpModalVisible(!hrSignUpModalVisible);
  };

  const toggleSignUpSelectionModal = () => {
    setSignUpSelectionModalVisible(!signUpSelectionModalVisible);
  };

  const toggleForgotPasswordModal = () => {
    setForgotPasswordModalVisible(!forgotPasswordModalVisible);
  };

  const toggleEmailUpdateModal = () => {
    setEmailUpdateModalVisible(!emailUpdateModalVisible);
  };

  const toggleEmailVerifyModal = () => {
    setEmailVerifyModalVisible(!emailVerifyModalVisible);
  };

  const togglePhotoUpdateModal = () => {
    setPhotoUpdateModalVisible(!photoUpdateModalVisible);
  };

  const togglePasswordUpdateModal = () => {
    setPasswordUpdateModalVisible(!passwordUpdateModalVisible);
  };

  const toggleImageViewModal = (image) => {
    setImageViewModalVisible(!imageViewModalVisible);
    if (!image) {
      setTimeout(() => {
        setImageToDisplay(image);
      }, 400);
    } else {
      setImageToDisplay(image);
    }
  };

  const toggleReportJobModal = (id) => {
    setReportJobModalVisible(!reportJobModalVisible);
    if (!id) {
        setReportJobId(null);
    } else {
      setReportJobId(id);
    }
  };

  const toggleReportProfileModal = (id) => {
    setReportProfileModalVisible(!reportProfileModalVisible);
    if (!id) {
        setReportProfileId(null);
    } else {
      setReportProfileId(id);
    }
  };

  const toggleLocationUpdateModal = () => {
    setLocationUpdateModalVisible(!locationUpdateModalVisible);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };


  return (
    <GlobalContext.Provider
      value={{
        themeDark,
        toggleTheme,
        showSidebarDashboard,
        toggleSidebarDashboard,
        videoModalVisible,
        toggleVideoModal,
        applicationModalVisible,
        toggleApplicationModal,
        signInModalVisible,
        toggleSignInModal,
        mobNumVerifyModalVisible,
        toggleMobNumVerifyModal,
        otpVerifyModalVisible,
        toggleOtpVerifyModal,
        signUpModalVisible,
        toggleSignUpModal,
        hrSignUpModalVisible,
        toggleHrSignUpModal,
        signUpSelectionModalVisible,
        toggleSignUpSelectionModal,
        forgotPasswordModalVisible,
        toggleForgotPasswordModal,
        emailUpdateModalVisible,
        toggleEmailUpdateModal,
        emailVerifyModalVisible,
        toggleEmailVerifyModal,
        photoUpdateModalVisible,
        togglePhotoUpdateModal,
        passwordUpdateModalVisible,
        togglePasswordUpdateModal,
        imageToDisplay,
        setImageToDisplay,
        imageViewModalVisible,
        toggleImageViewModal,
        reportJobModalVisible,
        toggleReportJobModal,
        reportProfileModalVisible,
        toggleReportProfileModal,
        locationUpdateModalVisible,
        toggleLocationUpdateModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
        header,
        setHeader,
        footer,
        setFooter,
        userName,
        setUserName,
        user, // Provide user state
        setUser, // Provide function to update user state
        profile,
        setProfile,
        token,
        setToken,
        isJobProvider,
        setIsJobProvider,
        mobileNumberVisible,
        setMobileNumberVisible,
        profileImageVisible,
        setProfileImageVisible,
        newJobsToday,
        newCompaniesToday,
        jobApplicantsCount,
        handleClose,
        setChatVisible,
        handleOpenChat,
        isChatVisible,
        jobProviderId,
        setJobProviderId,
        jobSeekerId,
        setJobSeekerId,
        coordinates,
        applicationId,
        reportJobId,
        reportProfileId,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
