import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import apiClient from '../../axiosConfig';
import axios from "axios";
import network from "../../../constants/Network";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalHrSignUp = (props) => {
  const [showPassFirst, setShowPassFirst] = useState(true);
  const [showPassSecond, setShowPassSecond] = useState(true);
  const [errors, setErrors] = useState({});
  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);

  const gContext = useContext(GlobalContext);

  const [roles, setRoles] = useState([]);
  const [pickers, setPickers] = useState([]);
  // const [selectedRole, setSelectedRole] = useState(null);
  // const [selectedSubRole, setSelectedSubRole] = useState(null);
  const [anyMoreSubrole, setAnyMoreSubrole] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [isNewInstitution, setIsNewInstitution] = useState(false);
  const imageInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');

  const [formData, setFormData] = useState({
    role: '',
    name_of_organization: '',
    company_register_number: '',
    area: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    email: '',
    mobile_number: '',
    institution: '',
    institution_image: null,
    institution_title: '',
    institution_description: '',
    institution_address: '',
    institution_location: '',
    institution_phone_number: '',
  });

  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, institution_image: file });
    setImageUrl(URL.createObjectURL(file));
  };

  const handleImageDelete = () => {
    setImageUrl('');

    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      institution_image: '',
    }));

  }

  const handleClose = () => {
    console.log("Resetting form data and closing modal");
    setErrors({});
    setFormData({
      role: '',
      name_of_organization: '',
      company_register_number: '',
      area: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      email: '',
      mobile_number: '',
      institution: '',
      institution_image: null,
      institution_title: '',
      institution_description: '',
      institution_address: '',
      institution_location: '',
      institution_phone_number: '',
    });
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
    setImageUrl('');
    setIsNewInstitution(false);
    setPickers([{ id: null, subRoles: roles.filter(role => role.parent_slug === 'job-provider') }]);
    setAnyMoreSubrole(true);
    gContext.toggleHrSignUpModal();
  };

  const handleSignInLink = () => {
    console.log("Resetting form data and opening sign in modal");
    setErrors({});
    setFormData({
      role: '',
      name_of_organization: '',
      company_register_number: '',
      area: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      email: '',
      mobile_number: '',
      institution: '',
      institution_image: null,
      institution_title: '',
      institution_description: '',
      institution_address: '',
      institution_location: '',
      institution_phone_number: '',
    });
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
    setImageUrl('');
    setIsNewInstitution(false);
    setPickers([{ id: null, subRoles: roles.filter(role => role.parent_slug === 'job-provider') }]);
    setAnyMoreSubrole(true);
    gContext.toggleHrSignUpModal();
    gContext.toggleSignInModal();
  };

  const togglePasswordFirst = () => {
    setShowPassFirst(!showPassFirst);
  };

  const togglePasswordSecond = () => {
    setShowPassSecond(!showPassSecond);
  };

  const fetchInstitutions = async () => {
    try {
      const response = await axios.get('/institutions-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const institutionData = (response.data.data || []);
        setInstitutions(institutionData);
        console.log('Fetched states:', institutionData);
      } else if (!response.data.success) {
        console.error('Failed to fetch institutions');
      }

    } catch (error) {
      console.error('Error fetching institutions:', error);
    }
  };

  const fetchJobProviderRoles = async () => {
    try {
      const response = await apiClient.get('/roles/job-provider-web');
      if (response.data.success && isMountedRef.current) {
        setRoles(response.data.data);
        setPickers([{ id: null, subRoles: response.data.data.filter(role => role.parent_slug === 'job-provider') }]);
        console.log('Fetched job provider roles:', response.data.data);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setRoles([]);
        }
        console.error('Failed to fetch job provider roles');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setRoles([]);
      }
      console.error('Error fetching job provider roles:', error);
    }
  };

  // the below is the code for selection of role field

  const handleRoleChange = (roleId, index) => {

    setAnyMoreSubrole(false);

    const newPickers = [...pickers];
    newPickers[index].id = roleId;

    setFormData(prevData => ({
      ...prevData,
      role: roleId,
    }));

    const nextLevelSubRoles = roles.filter(role => role.parent_id === parseInt(roleId));

    if (nextLevelSubRoles.length > 0) {

      newPickers.splice(index + 1);

      newPickers.push({ id: null, subRoles: nextLevelSubRoles });
      // setSelectedRole(newPickers[0].id);
      // setSelectedSubRole(roleId);
      setAnyMoreSubrole(true);
    } else {

      newPickers.splice(index + 1);
      // setSelectedRole(newPickers[0].id);
      // setSelectedSubRole(roleId);

      setErrors(prevErrors => ({
        ...prevErrors,
        role: ''
      }));

    }

    setPickers(newPickers);
  };

  // the the code for selection of role field till here



  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    let isValid = true;

    if (!isNewInstitution && !formData.institution) {
      setErrors(prevErrors => ({
        ...prevErrors,
        institution: 'Please select a hospital'
      }));
      isValid = false;
    }

    if (isNewInstitution && !formData.institution_title) {
      setErrors(prevErrors => ({
        ...prevErrors,
        institution_title: 'Name of hospital is required'
      }));
      isValid = false;
    }

    if (!formData.role || anyMoreSubrole) {
      setErrors(prevErrors => ({
        ...prevErrors,
        role: 'Select a role'
      }));
      isValid = false;
    }

    if (!formData.name_of_organization) {
      setErrors(prevErrors => ({
        ...prevErrors,
        name_of_organization: 'Name of organization is is required'
      }));
      isValid = false;
    }

    if (!formData.area) {
      setErrors(prevErrors => ({
        ...prevErrors,
        area: 'Area is is required'
      }));
      isValid = false;
    }

    if (!formData.city) {
      setErrors(prevErrors => ({
        ...prevErrors,
        city: 'City is is required'
      }));
      isValid = false;
    }

    if (!formData.state) {
      setErrors(prevErrors => ({
        ...prevErrors,
        state: 'State is is required'
      }));
      isValid = false;
    }

    if (!formData.country) {
      setErrors(prevErrors => ({
        ...prevErrors,
        country: 'Country is is required'
      }));
      isValid = false;
    }

    const pincodeRegex = /^\d{6}$/;
    if (!formData.pincode) {
      setErrors(prevErrors => ({
        ...prevErrors,
        pincode: 'Pincode is is required'
      }));
      isValid = false;
    } else if (!pincodeRegex.test(formData.pincode)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        pincode: 'Enter a valid pincode'
      }));
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Email is required'
      }));
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Enter a valid email address'
      }));
      isValid = false;
    }

    const mobileRegex = /^\d{10}$/;
    if (!formData.mobile_number) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mobile_number: "Mobile number is required"
      }));
      isValid = false;
    } else if (!mobileRegex.test(formData.mobile_number)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        mobile_number: "Enter a valid mobile number"
      }));
      isValid = false;
    }

    if (!isAgreedToTerms) {
      setErrors(prevErrors => ({
        ...prevErrors,
        isAgreedToTerms: 'Please accept the terms of service and privacy policy'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the hr formData:', formData);

    try {
      const response = await apiClient.post('company-register-request-web', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
      console.log('This is the response:', response);

      if (response.data.message) {
        console.log('Company registration successful');
        setFormData({
          role: '',
          name_of_organization: '',
          company_register_number: '',
          area: '',
          city: '',
          state: '',
          country: '',
          pincode: '',
          email: '',
          mobile_number: '',
          institution: '',
          institution_image: null,
          institution_title: '',
          institution_description: '',
          institution_address: '',
          institution_location: '',
          institution_phone_number: '',
        });
        if (imageInputRef.current) {
          imageInputRef.current.value = '';
        }
        setImageUrl('');
        setIsNewInstitution(false);
        setErrors({});
        setPickers([{ id: null, subRoles: roles.filter(role => role.parent_slug === 'job-provider') }]);
        setAnyMoreSubrole(true);
        gContext.toggleHrSignUpModal();
        gContext.toggleSignInModal();
      }

    } catch (error) {
      console.error('Error registering company:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            signUpError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          signUpError: 'Something went wrong, please try again',
        }));
      }
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobProviderRoles();
      await fetchInstitutions();
    };

    fetchData();
  }, []);

  const styles = ModalHrSignUpStyling();

  return (
    <ModalStyled
      {...props}
      size="xl"
      centered
      show={gContext.hrSignUpModalVisible}
      onHide={gContext.toggleHrSignUpModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white rounded-8 overflow-hidden">
          <div className="row no-gutters">
            <div className="col-lg-5 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                  <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Create a free account today
                  </h3>
                  <p className="mb-0 font-size-4 text-white">
                    Sign up now to get started and connect with top talent.
                  </p>
                </div>
                <div className="border-top border-default-color-2 mt-auto">
                  <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{gContext.newJobsToday}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New jobs posted today
                      </p>
                    </div>
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{gContext.newCompaniesToday}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New companies registered
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                {/* <div className="row">
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-linkedin pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Import from LinkedIn
                      </span>
                    </a>
                  </div>
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-google pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Import from Google
                      </span>
                    </a>
                  </div>
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-facebook-square pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Import from Facebook
                      </span>
                    </a>
                  </div>
                </div>
                <div className="or-devider">
                  <span className="font-size-3 line-height-reset">Or</span>
                </div> */}
                <form onSubmit={handleSubmit}>

                  {!isNewInstitution ? (
                    <div className="form-group">
                      <label
                        htmlFor="institution"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Hospital
                      </label>
                      <select
                        id={'institution'}
                        name={'institution'}
                        className="form-control"
                        value={formData.institution}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === 'other') {
                            setIsNewInstitution(true);
                            setFormData({ ...formData, institution: '' });
                          } else {
                            handleChange(e);
                          }
                        }}
                      >
                        <option value="">Select hospital</option>
                        {institutions.map(inst => (
                          <option key={inst.id} value={inst.id}>
                            {inst.title}
                          </option>
                        ))}
                        <option value="other">Other</option>
                      </select>
                      {errors?.institution ? <p className="font-size-3 text-danger">
                        {errors.institution}
                      </p> : null}
                    </div>
                  ) : (
                    <div>

                      <div className="form-group">
                        <label
                          htmlFor="institution_title"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Hospital name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter hospital name"
                          id="institution_title"
                          name="institution_title"
                          value={formData.institution_title}
                          onChange={handleChange}
                        />
                        {errors?.institution_title ? <p className="font-size-3 text-danger">
                          {errors.institution_title}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="institution_image"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Hospital image:
                        </label>
                        <input
                          type="file"
                          className="form-control-file"
                          name="institution_image"
                          onChange={handleImageChange}
                          id="institution_image"
                          ref={imageInputRef}
                        />
                        {errors?.institution_image ? <p className="font-size-3 text-danger">
                          {errors.institution_image}
                        </p> : null}
                        {imageUrl && (
                          <div className="d-flex justify-content-start mt-7">
                            <div style={styles.imageContainer}>
                              <img
                                src={imageUrl}
                                alt=""
                                className="img-fluid"
                                style={styles.imageStyle}
                              />
                              <button
                                type="button"
                                onClick={handleImageDelete}
                                style={styles.imageDeleteButton}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="institution_description"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Hospital description
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Enter description"
                          id="institution_description"
                          name="institution_description"
                          value={formData.institution_description}
                          onChange={handleChange}
                          rows="3"
                          style={{
                            overflow: 'auto', // Enable scrolling
                            resize: 'vertical' // Allow vertical resizing if needed, or set to 'both' for full resize
                          }}
                        />
                        {errors?.institution_description ? <p className="font-size-3 text-danger">
                          {errors.institution_description}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="institution_address"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Hospital address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter hospital address"
                          id="institution_address"
                          name="institution_address"
                          value={formData.institution_address}
                          onChange={handleChange}
                        />
                        {errors?.institution_address ? <p className="font-size-3 text-danger">
                          {errors.institution_address}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="institution_location"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Hospital location
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter hospital location"
                          id="institution_location"
                          name="institution_location"
                          value={formData.institution_location}
                          onChange={handleChange}
                        />
                        {errors?.institution_location ? <p className="font-size-3 text-danger">
                          {errors.institution_location}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="institution_phone_number"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Hospital phone number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter hospital phone number"
                          id="institution_phone_number"
                          name="institution_phone_number"
                          value={formData.institution_phone_number}
                          onChange={handleChange}
                        />
                        {errors?.institution_phone_number ? <p className="font-size-3 text-danger">
                          {errors.institution_phone_number}
                        </p> : null}
                      </div>

                    </div>
                  )}

                  {(roles.length !== 0) ? (
                    <div>
                      {pickers.map((picker, index) => (
                        <div key={index} className="form-group">
                          <label
                            htmlFor={`role-${index}`}
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            {index === 0 ? 'Are you a' : 'Sub-Role'}
                          </label>
                          <select
                            id={`role-${index}`}
                            name={`role-${index}`}
                            className="form-control"
                            value={picker.id || ''}
                            onChange={(event) => handleRoleChange(event.target.value, index)}
                          >
                            <option value="">Select a role</option>
                            {picker.subRoles.map(role => (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                      {errors?.role ? <p className="font-size-3 text-danger">
                        {errors.role}
                      </p> : null}
                    </div>
                  ) : (
                    <div className="form-group">
                      <p className="font-size-4 text-black-2 font-weight-semibold line-height-reset">Role: Please wait...</p>
                      {errors?.role ? <p className="font-size-3 text-danger">
                        {errors.role}
                      </p> : null}
                    </div>
                  )}

                  <div className="form-group">
                    <label
                      htmlFor="name_of_organization"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Name of organization
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name of organization"
                      id="name_of_organization"
                      name="name_of_organization"
                      value={formData.name_of_organization}
                      onChange={handleChange}
                    />
                    {errors?.name_of_organization ? <p className="font-size-3 text-danger">
                      {errors.name_of_organization}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="company_register_number"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Institute/company register number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Institute/company register number"
                      id="company_register_number"
                      name="company_register_number"
                      value={formData.company_register_number}
                      onChange={handleChange}
                    />
                    {errors?.company_register_number ? <p className="font-size-3 text-danger">
                      {errors.company_register_number}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="area"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Address
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Area"
                      id="area"
                      name="area"
                      value={formData.area}
                      onChange={handleChange}
                    />
                    {errors?.area ? <p className="font-size-3 text-danger">
                      {errors.area}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                    {errors?.city ? <p className="font-size-3 text-danger">
                      {errors.city}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                    />
                    {errors?.state ? <p className="font-size-3 text-danger">
                      {errors.state}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country"
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                    {errors?.country ? <p className="font-size-3 text-danger">
                      {errors.country}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pincode"
                      id="pincode"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleChange}
                    />
                    {errors?.pincode ? <p className="font-size-3 text-danger">
                      {errors.pincode}
                    </p> : null}
                  </div>


                  <div className="form-group">
                    <label
                      htmlFor="email"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="example@gmail.com"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors?.email ? <p className="font-size-3 text-danger">
                      {errors.email}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="mobile_number"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your mobile number"
                      id="mobile_number"
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleChange}
                    />
                    {errors?.mobile_number ? <p className="font-size-3 text-danger">
                      {errors.mobile_number}
                    </p> : null}
                  </div>

                  <div className="form-group d-flex flex-wrap justify-content-between">
                    <label
                      htmlFor="terms-check2"
                      className="gr-check-input d-flex  mr-3"
                    >
                      <input
                        className="d-none"
                        type="checkbox"
                        id="terms-check2"
                        checked={isAgreedToTerms}
                        onChange={(e) => setIsAgreedToTerms(e.target.checked)}
                      />
                      <span className="checkbox mr-5"></span>
                      <span className="font-size-3 line-height-reset d-block">
                        Agree to the{" "}
                        <a href="/#" className="text-primary">
                          Terms & Conditions
                        </a>
                      </span>
                    </label>
                    <a
                      href="/#"
                      className="font-size-3 text-dodger line-height-reset"
                    >
                      Forget Password
                    </a>
                    {errors?.isAgreedToTerms ? <p className="font-size-3 text-danger">
                      {errors.isAgreedToTerms}
                    </p> : null}
                  </div>

                  <div className="form-group mb-8 mt-1">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                        >
                          Assign Username
                        </button>
                      </div>
                      <div className="col-12 d-flex justify-content-center">
                        {errors?.hrSignUpError ? (
                          <p className="font-size-3 text-danger text-center">
                            {errors.hrSignUpError}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <p className="font-size-4 text-center heading-default-color">
                    Already a Member?{" "}
                    <button
                      type="button"
                      onClick={handleSignInLink}
                      className="text-primary"
                      style={styles.btnAsText}>
                      Login
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalHrSignUp;

function ModalHrSignUpStyling() {
  const styles = {
    btnAsText: {
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      color: 'inherit',
      font: 'inherit',
      cursor: 'pointer',
    },
    imageContainer: {
      position: 'relative',
      display: 'inline-block'
    },
    imageStyle: {
      maxHeight: '200px',
      width: 'auto'
    },
    imageDeleteButton: {
      position: 'absolute',
      top: '-15px',
      right: '-15px',
      background: 'white',
      color: 'gray',
      borderRadius: '50%',
      border: 'none',
      width: '30px',
      height: '30px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

  }
  return styles;
}
