import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { format } from "date-fns";
import {
  MainContainer,
  Sidebar,
  ConversationList,
  Conversation,
  Avatar,
  ChatContainer,
  ConversationHeader,
  MessageGroup,
  Message,
  MessageSeparator,
  MessageList,
  TypingIndicator,
  MessageInput,
  VoiceCallButton,
} from "@chatscope/chat-ui-kit-react";
import { FaWindowMinimize, FaTimes } from 'react-icons/fa';
import styled from "styled-components";
import { database, get, set, ref, push, onValue } from "../../utils/firebase";
import GlobalContext from "../../context/GlobalContext";
import network from "../../../constants/Network";

const ChatWrapper = styled.div`
  position: fixed;
  height: ${({ isMinimized }) => (isMinimized ? "60px" : "500px")};
  width: 500px;
  left: 5px;
  bottom: 0;
  z-index: 1000;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
`;

const ChatUI = () => {

  const styles = {
    mainContainer: {
    
    },
    sidebar: {
      backgroundColor: "#f7f9fc",
      borderRight: "1px solid #e5e5e5",
    },
    chatContainer: {
      background: "linear-gradient(to bottom, #f3f3f3, #ffffff)",
    },
    messageList: {
      padding: "10px",
      backgroundColor: "#ffffff",
    },
    messageInput: {
      borderTop: "1px solid #e5e5e5",
      backgroundColor: "#f9f9f9",
    },
    conversationHeader: {
      backgroundColor: "#ffffff",
      padding: "10px",
      borderBottom: "1px solid #e5e5e5",
    },
  };

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const gContext = useContext(GlobalContext);
  const { isChatVisible, handleClose } = gContext;

  // const { jobProviderId } = useContext(GlobalContext);
  // const jobProviderId = 3;

  const { jobProviderId, jobSeekerId, setJobSeekerId, setJobProviderId } = useContext(GlobalContext);

useEffect(() => {
  console.log("jobProviderId global context:", jobProviderId);
  console.log("jobSeekerId global context:", jobSeekerId);
}, [jobProviderId, jobSeekerId]);


  const [isMinimized, setIsMinimized] = useState(false);
  console.log("isMinimized:", isMinimized);

  const [conversations, setConversations] = useState([]);

  const [userDetails, setUserDetails] = useState(null);

  const [chatListUserDetails, setChatListUserDetails] = useState([]);
  

  const loggedInUserId = Cookies.get("user") ? JSON.parse(Cookies.get("user")).id : null;

  const [userImage, setUserImage] = useState(null);
  

  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loggedInProfile, setLoggedInProfile] = useState(null);

  const imageBaseUrl = `${network.serverip}/images/`; 
  const defaultImageUrl = `${imageBaseUrl}profile_default_photo.png`; // Default image URL

  const [selectedUser, setSelectedUser] = useState(null);

  const [isChatOpen, setIsChatOpen] = useState(false); // Add state to control chat container visibility

  const selectedConversationStyle = { backgroundColor: '#d3d3d3' }; // Style for the selected conversation
  const normalConversationStyle = { backgroundColor: '#fff' }; // Default background for non-selected

  const [typingUser, setTypingUser] = useState(null);

  

   // Handle conversation click
  //  const handleConversationClick = (user) => {
  //   setSelectedUser(user); // Update the selected user on click
  //   console.log('chatlist clicked user:', user);
  // };

  const handleConversationClick = async (user) => {
    setSelectedUser(user);
    setIsChatOpen(true); 
    console.log("chatlist clicked user:", user);
  
    if (!user.id) {
      console.error("User ID is missing in the selected user object:", user);
      return;
    }

    
  console.log("chatlist selected user id:", user.id);

  
    const rawCookieUser = Cookies.get("user");
    console.log("Raw cookie data for user:", rawCookieUser);
  
    if (!rawCookieUser) {
      console.error("No user cookie found. Ensure the user is logged in.");
      return;
    }
  
    const parsedUser = JSON.parse(rawCookieUser);
    const loggedInUserId = parsedUser.id;
    const isJobProvider = Cookies.get("isJobProvider") === "1";
  
    if (!loggedInUserId) {
      console.error("Logged-in user ID is missing.");
      return;
    }
  
    console.log("Logged-in user ID:", loggedInUserId, "Is job provider:", isJobProvider);

     // Set jobSeekerId and jobProviderId based on the logged-in user's role
  if (isJobProvider) {
    // Logged-in user is a Job Provider; set user.id as jobSeekerId
    setJobSeekerId(user.id);
    setJobProviderId(loggedInUserId);
    console.log("Set Job Provider ID to:", loggedInUserId, "and Job Seeker ID to:", user.id);
  } else {
    // Logged-in user is a Job Seeker; set user.id as jobProviderId
    setJobSeekerId(loggedInUserId);
    setJobProviderId(user.id);
    console.log("Set Job Seeker ID to:", loggedInUserId, "and Job Provider ID to:", user.id);
  }
  
    const messagesRef = ref(database, `chat/messages`);
    console.log("Firebase reference for messages:", messagesRef.toString());
  
    try {
      const snapshot = await get(messagesRef);
      console.log("Firebase snapshot raw value:", snapshot);
  
      const data = snapshot.val();
      console.log("Data from Firebase:", data);
  
      if (data) {
        console.log("Raw data from Firebase before formatting:", data);
  
        const filteredMessages = Object.keys(data)
          .map((key) => {
            const message = data[key];
            console.log("Processing message key:", key, "message data:", message);
  
            return {
              id: key,
              text: message.text,
              timestamp: message.timestamp,
              direction: message.job_seeker_id === loggedInUserId ? "outgoing" : "incoming",
              job_seeker_id: message.job_seeker_id,
              job_provider_id: message.job_provider_id,
            };
          })
          // Here, filter messages to show only those between the logged-in user and the clicked user (user.id)
          .filter(
            (message) =>
              (message.job_seeker_id === user.id || message.job_provider_id === user.id)
          );
  
        setMessages(filteredMessages);
        console.log("Filtered messages for clicked user:", filteredMessages);
      } else {
        console.warn("No data found for the selected conversation.");
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error.message, error.stack);
      setMessages([]);
    }
  };
  
  

// Get the logged-in user from cookies
useEffect(() => {
  const loggedInUserDetails = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const loggedInUserProfileDetails = Cookies.get("profile") ? JSON.parse(Cookies.get("profile")) : null;
  if (loggedInUserDetails || loggedInUserProfileDetails) {
    console.log("Logged in user details:", loggedInUser);
    setLoggedInUser(loggedInUserDetails);  // Store the logged-in user in state

    console.log("Logged in profile details:", loggedInUserProfileDetails);
    setLoggedInProfile(loggedInUserProfileDetails);
  }
}, []);


useEffect(() => {
  if (!loggedInUserId) {
    console.error("User is not logged in.");
    return;
  }

  const conversationsRef = ref(database, "chat/messages");

  // Fetch conversations
  onValue(conversationsRef, async (snapshot) => {
    const data = snapshot.val();
    if (data) {
      // Step 1: Filter conversations involving the logged-in user
      const userConversations = Object.keys(data)
        .map((key) => ({
          id: key,
          ...data[key],
        }))
        .filter(
          (conversation) =>
            conversation.job_provider_id === loggedInUserId ||
            conversation.job_seeker_id === loggedInUserId
        );

      setConversations(userConversations);

      // Step 2: Extract unique participant IDs (excluding logged-in user)
      const participantIds = [
        ...new Set(
          userConversations.flatMap((conv) => [
            conv.job_provider_id,
            conv.job_seeker_id,
          ])
        ),
      ].filter((id) => id !== loggedInUserId);

      // Step 3: Fetch user details for these IDs
      try {
        const response = await fetch(
          `${network.serverip}/api/chat-list-users?ids=${participantIds.join(",")}`
        );
        const userDetails = await response.json();
        setChatListUserDetails(userDetails);
        console.log('chatlistuserDetails:', userDetails);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    } else {
      setConversations([]);
      setChatListUserDetails([]);
    }
  });
}, [loggedInUserId]);


  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    console.log("Job provider ID in ChatUI:", jobProviderId);
    // Use jobProviderId as needed
  }, [jobProviderId]);

  useEffect(() => {
    const messagesRef = ref(database, "chat/messages");
  
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const filteredMessages = Object.keys(data)
          .map((key) => ({
            id: key,
            ...data[key],
          }))
          .filter(
          //   (message) =>
          //     (message.job_seeker_id === loggedInUserId && message.job_provider_id === jobProviderId) ||
          //     (message.job_seeker_id === jobProviderId && message.job_provider_id === loggedInUserId)
          // );
          (message) =>
            message.job_seeker_id === loggedInUserId ||
            message.job_provider_id === loggedInUserId
        );
        setMessages(filteredMessages);
        console.log("Filtered messages for logged-in user:", filteredMessages);
      } else {
        setMessages([]);
      }
    });
  
    return () => unsubscribe();
  }, [loggedInUserId, jobProviderId]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const isJobProvider = Cookies.get("isJobProvider") === "1"; // Check if logged-in user is a job provider
        const loggedInUserId = Cookies.get("user") ? JSON.parse(Cookies.get("user")).id : null;
  
        // Determine jobProviderId and jobSeekerId based on role
        // Determine the role of the logged-in user
      const jobSeekerId = isJobProvider ? null : loggedInUserId;
      const jobProviderId = isJobProvider ? loggedInUserId : 3; // Assume 3 is the static job provider ID.

      console.log('Determine jobSeekerId:', jobSeekerId, 'Determine jobProviderId:', jobProviderId);
  
        if (!jobProviderId || !jobSeekerId) {
          console.error("Missing IDs for job provider or job seeker.");
          return;
        }
  
        const response = await fetch(
          `${network.serverip}/api/users/details?job_provider_id=${jobProviderId}&job_seeker_id=${jobSeekerId}`
        );
        const data = await response.json();
  
        if (response.ok) {
          setUserDetails(data);
          console.log("Chat user & profile details:", data);
          

          const imageBaseUrl = `${network.serverip}/images/`;

          const defaultProfilePhoto = `${imageBaseUrl}Profile_default_photo.jpg`; // Path to your default profile photo
          console.log("defaultProfilePhoto image URL:", defaultProfilePhoto);

          // const userImage = isJobProvider
          //   ? data.job_provider.profile.image
          //     ? `${imageBaseUrl}${data.job_provider.profile.image}`
          //     : defaultProfilePhoto
          //   : data.job_seeker.profile.image
          //   ? `${imageBaseUrl}${data.job_seeker.profile.image}`
          //   : defaultProfilePhoto;
            
          // setUserImage(userImage);
          const userImage = isJobProvider
  ? data.job_provider?.profile?.image
    ? `${imageBaseUrl}${data.job_provider.profile.image}`
    : defaultProfilePhoto
  : data.job_seeker?.profile?.image
  ? `${imageBaseUrl}${data.job_seeker.profile.image}`
  : defaultProfilePhoto;

setUserImage(userImage);

          console.log("User image URL:", userImage);
        } else {
          console.error("Error fetching user & profile details:", data.error);
        }
      } catch (error) {
        console.error("Error fetching user & profile details:", error);
      }
    };
  
    fetchUserDetails();
  }, []);
  

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const messagesRef = ref(database, "chat/messages");
      const loggedInUserId = Cookies.get("user") ? JSON.parse(Cookies.get("user")).id : null;
const isJobProvider = Cookies.get("isJobProvider") === "1";
console.log("loggedInUserId:", loggedInUserId, "jobProviderId:", jobProviderId);

 // If either jobProviderId or jobSeekerId is null, use selectedUser.id as fallback
//  const jobSeekerIdToUse = jobSeekerId || selectedUser?.id;
//  const jobProviderIdToUse = jobProviderId || selectedUser?.id;


if (!loggedInUserId || !jobProviderId) {
  console.error("Missing required IDs: jobProviderId or loggedInUserId is undefined.");
  return;
}

  
      push(messagesRef, {
        text: newMessage,
        timestamp: Date.now(),
        // job_seeker_id: isJobProvider ? jobProviderId : loggedInUserId,
        // job_provider_id: isJobProvider ? loggedInUserId : jobProviderId,
        job_provider_id: jobProviderId, 
        job_seeker_id: jobSeekerId,
      });

      console.log("Sending message:", {
        job_seeker_id: isJobProvider ? jobProviderId : loggedInUserId,
        job_provider_id: isJobProvider ? loggedInUserId : jobProviderId,
      });
      
  
      setNewMessage(""); // Clear input field
    }
  };
  
  useEffect(() => {
    if (jobProviderId) {
      console.log("Job provider ID is available:", jobProviderId);
    } else {
      console.log("Job provider ID is not available yet.");
    }
  }, [jobProviderId]);

  const formatDate = (timestamp) => {
    return format(new Date(timestamp), "EEEE, dd MMMM yyyy");
  };

  useEffect(() => {
    const typingRef = ref(database, "chat/typing");
  
    const unsubscribe = onValue(typingRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Assume data contains { userId: "User Name" } or null when no one is typing
        setTypingUser(data.userName || null);
      } else {
        setTypingUser(null);
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  const handleTyping = (isTyping) => {
  const typingRef = ref(database, "chat/typing");

  if (isTyping) {
    set(typingRef, { userId: loggedInUserId, userName: loggedInUser.name });
  } else {
    set(typingRef, null);
  }
};
  
useEffect(() => {
  if (isChatVisible && chatListUserDetails.length > 0 && !selectedUser) {
    const defaultUser = chatListUserDetails[0]; // Auto-select the first user
    handleConversationClick(defaultUser);
  }
}, [isChatVisible, chatListUserDetails]);

  if (!isChatVisible) return null;

  return (
    <ChatWrapper isMinimized={isMinimized}>
      <MainContainer responsive style={{ ...styles.mainContainer, display: 'flex', flexDirection: 'row' }}>
        <Sidebar 
          position="left" 
          scrollable 
          style={{
            ...styles.sidebar, 
            flexGrow: 1, // Sidebar takes the full available width when chat is closed
            width: isChatOpen ? 'auto' : '100%' // Sidebar width adjusts based on chat visibility
          }}
        >
          <ConversationHeader style={styles.conversationHeader}>
            <Avatar src={userImage} />
            <ConversationHeader.Content>{loggedInUser ? loggedInUser.name : "Loading..."}</ConversationHeader.Content>
            {/* <ConversationHeader.Actions>
                <FaWindowMinimize
                  size={20}
                  onClick={handleMinimize}
                  color="#184169"
                />
                <FaTimes size={20} onClick={handleClose} color="#184169" />
              </ConversationHeader.Actions> */}
          </ConversationHeader>
          <ConversationList>
            {chatListUserDetails.slice().reverse().map((user) => {
              console.log('chatListUserDetails:', user); // Log each user individually
  
              // Conditionally set the image URL
              const imageUrl = user.image ? `${imageBaseUrl}${user.image}` : defaultImageUrl; // Default if no image
  
              // Check if this conversation is the selected one
              const isSelected = selectedUser && selectedUser.id === user.id;
  
              return (
                <Conversation
                  key={user.id}
                  name={user.name}
                  onClick={() => handleConversationClick(user)} // Handle click
                  style={isSelected ? selectedConversationStyle : normalConversationStyle} // Apply background color conditionally
                >
                  <Avatar
                    src={imageUrl} // Replace with dynamic avatar URL if available
                    name={user.name}
                  />
                </Conversation>
              );
            })}
          </ConversationList>
        </Sidebar>
  
        {/* Conditionally render the ChatContainer based on isChatOpen */}
        {isChatOpen && (
          <ChatContainer style={styles.chatContainer}>
            <ConversationHeader>
              {/* Dynamically change the avatar and name */}
              <Avatar src={selectedUser ? `${imageBaseUrl}${selectedUser.image || 'profile_default_photo.png'}` : defaultImageUrl} />
              <ConversationHeader.Content userName={selectedUser ? selectedUser.name : 'Select a user'} />
              <ConversationHeader.Actions>
                <VoiceCallButton color="#184169" />
                <FaWindowMinimize
                  size={20}
                  onClick={handleMinimize}
                  color="#184169"
                />
                <FaTimes size={20} onClick={handleClose} color="#184169" />
              </ConversationHeader.Actions>
            </ConversationHeader>
            
            <MessageList
  typingIndicator={
    typingUser ? <TypingIndicator content={`${typingUser} is typing...`} /> : null
  }
  style={styles.messageList}
>

              {messages.length > 0 ? (
                messages.map((message, index) => {
                  const currentDate = formatDate(message.timestamp);
                  const previousDate =
                    index > 0 ? formatDate(messages[index - 1].timestamp) : null;

                  const shouldShowSeparator = currentDate !== previousDate;

                  return (
                    <React.Fragment key={message.id}>
                      {shouldShowSeparator && (
                        <MessageSeparator content={currentDate} />
                      )}
                      <MessageGroup
                        direction={
                          message.job_seeker_id === loggedInUserId ||
                          message.job_provider_id === loggedInUserId
                            ? "outgoing"
                            : "incoming"
                        }
                      >
                        <MessageGroup.Messages>
                          <Message
                            model={{
                              type: "text",
                              payload: message.text,
                              direction:
                              message.job_seeker_id === loggedInUserId ||
                              message.job_provider_id === loggedInUserId
                                  ? "outgoing"
                                  : "incoming",
                              position: "normal",
                            }}
                          />
                        </MessageGroup.Messages>
                      </MessageGroup>
                    </React.Fragment>
                  );
                })
              ) : (
                <div>Start a conversation now!</div>
              )}
            </MessageList>

  
            {!isMinimized && (
              // <MessageInput
              //   placeholder="Type your message..."
              //   value={newMessage}
              //   onChange={(val) => setNewMessage(val)}
              //   onSend={() => handleSendMessage()} // Wrap in a function
              // />
              // Use this function in your input field's `onChange` and `onBlur` events.
<MessageInput
 onChange={(value) => {
  setNewMessage(value); // Directly use the value if it's passed
  handleTyping(true); // Notify typing
}}

  onBlur={() => handleTyping(false)} // Notify stop typing
  onSend={() => handleSendMessage()}
/>
            )}
          </ChatContainer>
        )}
      </MainContainer>
    </ChatWrapper>
  );
  
};

export default ChatUI;
