// import React, { useContext, useState } from "react";
// import styled from "styled-components";
// import { Modal } from "react-bootstrap";
// import GlobalContext from "../../context/GlobalContext";
// import apiClient from '../../axiosConfig';

// const ModalStyled = styled(Modal)`
//   /* &.modal {
//     z-index: 10050;
//   } */
// `;

// const ModalSignIn = (props) => {
//   const [showPass, setShowPass] = useState(true);
//   const gContext = useContext(GlobalContext);

//   const handleClose = () => {
//     gContext.toggleSignInModal();
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const togglePassword = () => {
//     setShowPass(!showPass);
//   };

//   const [formData, setFormData] = useState({   
//     email: '',
//     password: ''
//   });

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     apiClient.post('weblogin', formData)
//       .then(response => {
//         console.log('User logged successfully:', response.data);

//         if(response.data.status!=false)
//           {
//             gContext.setUser(response.data.user); // Update user state on success
//             handleClose();
//             console.log(gContext.user);
//           }

//       })
//       .catch(error => {
//         console.error('There was an error creating the user:', error);
//       });
//   };

//   return (
//     <ModalStyled
//       {...props}
//       size="lg"
//       centered
//       show={gContext.signInModalVisible}
//       onHide={gContext.toggleSignInModal}
//     >
//       <Modal.Body className="p-0">
//         <button
//           type="button"
//           className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
//           onClick={handleClose}
//         >
//           <i className="fas fa-times"></i>
//         </button>
//         <div className="login-modal-main bg-white rounded-8 overflow-hidden">
//           <div className="row no-gutters">
//             <div className="col-lg-5 col-md-6">
//               <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
//                 <div className="pb-9">
//                   <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
//                     Welcome Back
//                   </h3>
//                   <p className="mb-0 font-size-4 text-white">
//                     Log in to continue your account and explore new jobs.
//                   </p>
//                 </div>
//                 <div className="border-top border-default-color-2 mt-auto">
//                   <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
//                     <div className="pt-5 px-9">
//                       <h3 className="font-size-7 text-white">295</h3>
//                       <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
//                         New jobs posted today
//                       </p>
//                     </div>
//                     <div className="pt-5 px-9">
//                       <h3 className="font-size-7 text-white">14</h3>
//                       <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
//                         New companies registered
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-6">
//               <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
//                 <div className="row">
//                   <div className="col-4 col-xs-12">
//                     <a
//                       href="/#"
//                       className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
//                     >
//                       <i className="fab fa-linkedin pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
//                       <span className="d-none d-xs-block">
//                         Log in with LinkedIn
//                       </span>
//                     </a>
//                   </div>
//                   <div className="col-4 col-xs-12">
//                     <a
//                       href="/#"
//                       className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
//                     >
//                       <i className="fab fa-google pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
//                       <span className="d-none d-xs-block">
//                         Log in with Google
//                       </span>
//                     </a>
//                   </div>
//                   <div className="col-4 col-xs-12">
//                     <a
//                       href="/#"
//                       className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
//                     >
//                       <i className="fab fa-facebook-square pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
//                       <span className="d-none d-xs-block">
//                         Log in with Facebook
//                       </span>
//                     </a>
//                   </div>
//                 </div>
//                 <div className="or-devider">
//                   <span className="font-size-3 line-height-reset ">Or</span>
//                 </div>
//                 <form onSubmit={handleSubmit}>
//                   <div className="form-group">
//                     <label
//                       htmlFor="email"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       E-mail
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       placeholder="example@gmail.com"
//                       id="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label
//                       htmlFor="password"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Password
//                     </label>
//                     <div className="position-relative">
//                       <input
//                         type={showPass ? "password" : "text"}
//                         className="form-control"
//                         id="password"
//                         name="password"
//                         placeholder="Enter password"
//                         value={formData.password}
//                         onChange={handleChange}
//                       />
//                       <a
//                         href="/#"
//                         className="show-password pos-abs-cr fas mr-6 text-black-2"
//                         onClick={(e) => {
//                           e.preventDefault();
//                           togglePassword();
//                         }}
//                       >
//                         <span className="d-none">none</span>
//                       </a>
//                     </div>
//                   </div>
//                   <div className="form-group d-flex flex-wrap justify-content-between">
//                     <label
//                       htmlFor="terms-check"
//                       className="gr-check-input d-flex  mr-3"
//                     >
//                       <input
//                         className="d-none"
//                         type="checkbox"
//                         id="terms-check"
//                       />
//                       <span className="checkbox mr-5"></span>
//                       <span className="font-size-3 mb-0 line-height-reset mb-1 d-block">
//                         Remember password
//                       </span>
//                     </label>
//                     <a
//                       href="/#"
//                       className="font-size-3 text-dodger line-height-reset"
//                     >
//                       Forget Password
//                     </a>
//                   </div>
//                   <div className="form-group mb-8">
//                     <button className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
//                       Log in{" "}
//                     </button>
//                   </div>
//                   <p className="font-size-4 text-center heading-default-color">
//                     Don’t have an account?{" "}
//                     <a href="/#" className="text-primary">
//                       Create a free account
//                     </a>
//                   </p>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//     </ModalStyled>
//   );
// };

// export default ModalSignIn;

import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import apiClient from '../../axiosConfig';

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalSignIn = (props) => {
  const [showPass, setShowPass] = useState(false);
  const gContext = useContext(GlobalContext);
  const [errors, setErrors] = useState({});
  const [showMobVerifyMessage, setShowMobVerifyMessage] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const handleClose = () => {
    console.log("Resetting form data and closing modal");
    setErrors({});
    setFormData({
      username: '',
      password: '',
    });
    setShowMobVerifyMessage(false);
    gContext.toggleSignInModal();
    // gContext.toggleMobNumVerifyModal();
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  const handleMobNumbVerify = () => {
    console.log("handleMobNumVerify clicked");
    setErrors({});
    gContext.setUserName(formData.username);
    setFormData({
      username: '',
      password: '',
    });
    setShowMobVerifyMessage(false);
    gContext.toggleSignInModal();
    gContext.toggleMobNumVerifyModal();
  }

  // const handleMobNumbVerify = async () => {

  //   console.log('Handle mobile number verify pressed');

  //   setErrors({});

  //   let isValid = true;

  //   if (!formData.username) {
  //     setErrors(prevErrors => ({
  //       ...prevErrors,
  //       username: 'Mobile number is required'
  //     }));
  //     isValid = false;
  //   }

  //   if (!isValid) {
  //     return
  //   }

  //   console.log('This is the mobile number:', formData.username);

  //   try {
  //     const response = await apiClient.post('verify-mobile-number-web', {
  //       mobile_number: formData.username,
  //     });

  //     console.log('This is the response:', response);

  //     if (response.data.message) {
  //       setErrors({});
  //       gContext.setUserName(formData.username);   
  //       setFormData({
  //         username: '',
  //         password: '',
  //       });
  //       setShowMobVerifyMessage(false);
  //       gContext.toggleSignInModal();
  //       gContext.toggleMobNumVerifyModal();
  //     }

  //   } catch (error) {
  //     console.log('This is the catch response:', error.response);

  //     console.error('Error while generating OTP:', error);

  //     // Handle specific error responses from the backend
  //     if (error.response) {
  //       if (error.response.data.errors) {
  //         setErrors(prevErrors => ({
  //           ...prevErrors,
  //           ...error.response.data.errors,
  //         }));
  //         return
  //       }

  //       if (error.response.data.message) {
  //         setErrors(prevErrors => ({
  //           ...prevErrors,
  //           otpGenerateError: error.response.data.message,
  //         }));
  //       }
  //     } else {
  //       setErrors(prevErrors => ({
  //         ...prevErrors,
  //         otpGenerateError: 'Something went wrong, please try again',
  //       }));
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Handle submit pressed');

    setErrors({});
    setShowMobVerifyMessage(false);

    let isValid = true;

    if (!formData.username) {
      setErrors(prevErrors => ({
        ...prevErrors,
        username: 'Username / mobile number is required'
      }));
      isValid = false;
    }

    if (!formData.password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'Password is required'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the login formData:', formData);

    try {
      const response = await apiClient.post('login-web', formData);

      console.log('This is the response:', response);

      if (response.data.message) {
        console.log('Redirecting to otp verification modal');

        gContext.setUserName(formData.username);

        setFormData({
          username: '',
          password: '',
        });

        setErrors({});
        gContext.toggleSignInModal();
        gContext.toggleOtpVerifyModal();
      }

    } catch (error) {

      console.error('Error to login:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
        }

        if (error.response.data.message === 'Mobile number is not verified') {
          setShowMobVerifyMessage(true);
          return
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          loginError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handleSignUpLink = () => {
    console.log("Resetting form data and opening sign in modal");
    setErrors({});
    setFormData({
      username: '',
      password: '',
    });
    gContext.toggleSignInModal();
    gContext.toggleSignUpSelectionModal();
  };

  const handleForgotPassword = () => {
    console.log("Resetting form data and opening forgot password modal");
    setErrors({});
    setFormData({
      username: '',
      password: '',
    });
    gContext.toggleSignInModal();
    gContext.toggleForgotPasswordModal();
  };

  const styles = ModalSignInStyling();

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.signInModalVisible}
      onHide={gContext.toggleSignInModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white rounded-8 overflow-hidden">
          <div className="row no-gutters">
            <div className="col-lg-5 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                  <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Welcome Back
                  </h3>
                  <p className="mb-0 font-size-4 text-white">
                    Log in to access your account and discover new opportunities.
                  </p>
                </div>
                <div className="border-top border-default-color-2 mt-auto">
                  <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{gContext.newJobsToday}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New jobs posted today
                      </p>
                    </div>
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{gContext.newCompaniesToday}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New companies registered
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                {/* <div className="row">
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-linkedin pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Log in with LinkedIn
                      </span>
                    </a>
                  </div>
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-google pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Log in with Google
                      </span>
                    </a>
                  </div>
                  <div className="col-4 col-xs-12">
                    <a
                      href="/#"
                      className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4"
                    >
                      <i className="fab fa-facebook-square pos-xs-abs-cl font-size-7 ml-xs-4"></i>{" "}
                      <span className="d-none d-xs-block">
                        Log in with Facebook
                      </span>
                    </a>
                  </div>
                </div> */}
                {/* <div className="or-devider">
                  <span className="font-size-3 line-height-reset ">Or</span>
                </div> */}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label
                      htmlFor="username"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Username or mobile number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username or mobile number"
                      id="username"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                    {errors?.username ? <p className="font-size-3 text-danger">
                      {errors.username}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPass ? "text" : "password"}
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Enter password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <a
                        href="/#"
                        className="pos-abs-cr mr-6 text-black-2"
                        onClick={(e) => {
                          e.preventDefault();
                          togglePassword();
                        }}
                      >
                        <i className={`fas ${showPass ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </a>
                    </div>
                    {errors?.password ? <p className="font-size-3 text-danger">
                      {errors.password}
                    </p> : null}
                  </div>

                  <div className="form-group d-flex flex-wrap justify-content-between">
                    {/* <label
                      htmlFor="terms-check"
                      className="gr-check-input d-flex  mr-3"
                    >
                      <input
                        className="d-none"
                        type="checkbox"
                        id="terms-check"
                      />
                      <span className="checkbox mr-5"></span>
                      <span className="font-size-3 mb-0 line-height-reset mb-1 d-block">
                        Remember password
                      </span>
                    </label> */}
                    {/* <a
                      href="/#"
                      className="font-size-3 text-dodger line-height-reset"
                    >
                      Forgot Password
                    </a> */}
                    <div class="d-flex flex-column align-items-start">
                      <button
                        type="button"
                        onClick={handleForgotPassword}
                        className="font-size-3 text-dodger line-height-reset mb-1 d-block"
                        style={styles.btnAsText}>
                        Forgot Password
                      </button>
                    </div>
                  </div>
                  <div className="form-group mb-8">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                          Log in{" "}
                        </button>
                      </div>
                      <div className="col-12 d-flex justify-content-center">
                        {errors?.loginError ? (
                          <p className="font-size-3 text-danger text-center">
                            {errors.loginError}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {showMobVerifyMessage &&
                    <div className="d-flex justify-content-center align-items-center mb-4">
                      <p className="font-size-3 text-danger mb-0">
                        Your mobile number is not verified.
                      </p>
                      <button
                        type="button"
                        className="font-size-3 font-weight-bold text-dodger text-center ml-2"
                        style={{ ...styles.btnAsText, textDecoration: 'underline' }}
                        onClick={handleMobNumbVerify}
                      >
                        Verify
                      </button>
                    </div>
                  }
                  <p className="font-size-4 text-center heading-default-color">
                    Don’t have an account?{" "}
                    {/* <a href="/#" className="text-primary">
                      Create a free account
                    </a> */}
                    <button
                      type="button"
                      onClick={handleSignUpLink}
                      className="text-primary"
                      style={styles.btnAsText}>
                      Create a free account
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalSignIn;

function ModalSignInStyling() {
  const styles = {
    btnAsText: {
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      color: 'inherit',
      // font: 'inherit',
      cursor: 'pointer',
    }
  }
  return styles;
}