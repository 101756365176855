import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";

import styled, { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";

import Header from "../Header";
import Footer from "../Footer";

import SidebarDashboard from "../SidebarDashboard";
import ModalVideo from "../ModalVideo";
import ModalApplication from "../ModalApplication";
// import ModalSignIn from "../ModalSignIn";
// import ModalSignUp from "../ModalSignUp";
import { ModalSignIn, ModalOtpVerify, ModalMobNumVerify, ModalForgotPassword } from "../ModalSignIn";
import { ModalSignUp, ModalHrSignUp, ModalSignUpSelection } from "../ModalSignUp";
import { ModalEmailUpdate, ModalPhotoUpdate, ModalPasswordUpdate, ModalEmailVerify, ModalLocationUpdate } from "../ModalProfileUpdate";
import { ModalImageView } from "../ModalView";
import { ModalReportJob, ModalReportProfile } from "../ModalReport";

import GlobalContext from "../../context/GlobalContext";

import GlobalStyle from "../../utils/globalStyle";

import imgFavicon from "../../assets/favicon-32x32.png";

import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/fontawesome-5/css/all.css";

import "../../scss/bootstrap.scss";
import "../../scss/main.scss";
import TawkToScript from "../../components/TawkTo/TawkToScript";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { FaWindowMinimize, FaTimes } from 'react-icons/fa';
import userimage from '../../assets/image/user.png';
import Cookies from 'js-cookie';
import ChatUI from "../../components/ChatUI/ChatUI";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Avatar,
  Conversation,
  ConversationList,
  MessageGroup,
  MessageSeparator,
  AttachmentButton,
  SendButton,
  Status,
  ConversationHeader,
  VoiceCallButton,
  VideoCallButton,
  InfoButton,
  Sidebar,
  Search,
  lillyIco,
  joeIco,
  emilyIco,
  kaiIco,
  akaneIco,
  eliotIco,
  zoeIco,
  patrikIco,
  EllipsisButton
} from "@chatscope/chat-ui-kit-react";


import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../../utils";

import { database, ref, push, onValue } from "../../utils/firebase";
// import { getDatabase, ref, onValue, push } from "firebase/database";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;


// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext);
  const { isChatVisible, handleOpenChat, handleClose } = useContext(GlobalContext);
  const [visibleLoader, setVisibleLoader] = useState(true);



  useLayoutEffect(() => {
    AOS.init({ once: true });
    setVisibleLoader(false);
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
  }, [gContext]);
  const tawkPropertyId = "bc6d6e5bd130dd36bae98b8e9e51e072bebe1e8c"; // replace with actual ID
  const tawkWidgetId = "1ibifnj3f"; // replace with actual ID if different

  const [isMinimized, setIsMinimized] = useState(false);

  console.log("isMinimized:", isMinimized);
  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  console.log("isChatVisible:", isChatVisible);

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");


  useEffect(() => {
    const messagesRef = ref(database, "chat/messages");

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      console.log("Received snapshot data:", data);

      const messagesArray = data
        ? Object.keys(data).map((key) => ({
          id: key,
          text: data[key].text,
          timestamp: data[key].timestamp,
          job_seeker_id: data[key].job_seeker_id,
          job_provider_id: data[key].job_provider_id,
        }))
        : [];

      setMessages(messagesArray);
      console.log("Formatted messages array:", messagesArray);
    });

    return () => unsubscribe();
  }, []);





  // Handle sending a new message
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const messagesRef = ref(database, "chat/messages");
      const isJobProvider = Cookies.get('isJobProvider') === '1'; // Retrieve role from cookies
      const loggedInUserId = JSON.parse(Cookies.get('user')).id; // Retrieve logged-in user ID

      push(messagesRef, {
        text: newMessage,
        timestamp: Date.now(),
        job_seeker_id: isJobProvider ? 2 : loggedInUserId, // Dynamic IDs
        job_provider_id: isJobProvider ? loggedInUserId : 2,
      });

      setNewMessage(""); // Clear input field
    }
  };



  const ChatUII = isChatVisible && (
    <div
      style={{
        position: "fixed",
        height: isMinimized ? "60px" : "500px",
        width: "300px",
        left: "5px",
        bottom: "0",
        zIndex: "1000",
        border: "1px solid #ccc",
        borderRadius: "8px",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <MainContainer>
        <ChatContainer>
          {/* Conversation Header */}
          <ConversationHeader>
            <Avatar src={userimage} name="Zoe" />
            <ConversationHeader.Content userName="Zoe" />
            <ConversationHeader.Actions>
              <VoiceCallButton color="#184169" />
              <FaWindowMinimize
                size={20}
                onClick={handleMinimize}
                color="#184169"
              />
              <FaTimes size={20} onClick={handleClose} color="#184169" />
            </ConversationHeader.Actions>
          </ConversationHeader>

          {/* Message List */}
          <MessageList
            typingIndicator={<TypingIndicator content="Zoe is typing" />}
            style={{
              display: isMinimized ? "none" : "block",
              height: "calc(100% - 120px)", // Adjust height for header and input
            }}
          >
            {messages.map((msg) => {
              const loggedInUserId = Cookies.get('user') ? JSON.parse(Cookies.get('user')).id : null;
              const isJobProvider = Cookies.get('isJobProvider') === '1';

              // Ensure loggedInUserId is defined
              if (!loggedInUserId) {
                console.error('loggedInUserId is not available in cookies');
                return null;
              }

              // Determine message direction
              const isOutgoing = isJobProvider
                ? msg.job_provider_id === loggedInUserId
                : msg.job_seeker_id === loggedInUserId;

              return (
                <Message
                  key={msg.id}
                  model={{
                    message: msg.text,
                    sentTime: new Date(msg.timestamp).toLocaleTimeString(),
                    sender: isOutgoing ? 'You' : 'Other',
                    direction: isOutgoing ? 'outgoing' : 'incoming',
                    position: 'single',
                  }}
                >
                  {!isOutgoing && <Avatar src={userimage} name="Zoe" />}
                </Message>
              );
            })}


          </MessageList>

          {/* Message Input */}
          {!isMinimized && (
            <MessageInput
              placeholder="Type your message here..."
              value={newMessage}
              onChange={(val) => setNewMessage(val)}
              onSend={handleSendMessage}
            />
          )}
        </ChatContainer>
      </MainContainer>
    </div>
  );



  if (pageContext.layout === "bare") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>Stackle - Medical Jobs & Profiles</title>
            <link rel="icon" type="image/png" href={imgFavicon} />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            {children}
            <TawkToScript />
          </div>
          {/* {ChatUI} */}
          {/* Chat Icon */}
          <button
            style={{
              position: "fixed",
              bottom: "20px",
              left: "20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              cursor: "pointer",
              fontSize: "24px",
            }}
            onClick={isChatVisible ? handleClose : handleOpenChat}
          >
            💬
          </button>
          {/* Conditionally Render ChatUI */}
          {isChatVisible && <ChatUI />}
          <ModalVideo />
          <ModalApplication />
          <ModalSignIn />
          <ModalMobNumVerify />
          <ModalOtpVerify />
          <ModalSignUp />
          <ModalHrSignUp />
          <ModalSignUpSelection />
          <ModalForgotPassword />
          <ModalEmailUpdate />
          <ModalEmailVerify />
          <ModalPhotoUpdate />
          <ModalPasswordUpdate />
          <ModalImageView />
          <ModalReportJob />
          <ModalReportProfile />
          <ModalLocationUpdate />
        </div>
      </ThemeProvider>
    );
  }

  if (pageContext.layout === "dashboard") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>Stackle - Medical Jobs & Profiles</title>
            <link rel="icon" type="image/png" href={imgFavicon} />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div
            className="site-wrapper overflow-hidden bg-default-2"
            ref={eleRef}
          >
            <Header isDark={gContext.headerDark} />
            <SidebarDashboard />
            {children}
          </div>
          {/* {ChatUI} */}
          <button
            style={{
              position: "fixed",
              bottom: "20px",
              left: "20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              cursor: "pointer",
              fontSize: "24px",
            }}
            onClick={isChatVisible ? handleClose : handleOpenChat}
          >
            💬
          </button>
          {/* Conditionally Render ChatUI */}
          {isChatVisible && <ChatUI />}
          <ModalVideo />
          <ModalApplication />
          <ModalSignIn />
          <ModalMobNumVerify />
          <ModalOtpVerify />
          <ModalSignUp />
          <ModalHrSignUp />
          <ModalSignUpSelection />
          <ModalForgotPassword />
          <ModalEmailUpdate />
          <ModalEmailVerify />
          <ModalPhotoUpdate />
          <ModalPasswordUpdate />
          <ModalImageView />
          <ModalReportJob />
          <ModalReportProfile />
          <ModalLocationUpdate />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>Stackle - Medical Jobs & Profiles</title>
            <link rel="icon" type="image/png" href={imgFavicon} />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"} />
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            <Header isDark={gContext.headerDark} />
            {children}

            <Footer isDark={gContext.footerDark} />
            <TawkToScript />
          </div>
          {/* {ChatUI} */}
          <button
            style={{
              position: "fixed",
              bottom: "20px",
              left: "20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              cursor: "pointer",
              fontSize: "24px",
            }}
            onClick={isChatVisible ? handleClose : handleOpenChat}
          >
            💬
          </button>
          {/* Conditionally Render ChatUI */}
          {isChatVisible && <ChatUI />}
          <ModalVideo />
          <ModalApplication />
          <ModalSignIn />
          <ModalMobNumVerify />
          <ModalOtpVerify />
          <ModalSignUp />
          <ModalHrSignUp />
          <ModalSignUpSelection />
          <ModalForgotPassword />
          <ModalEmailUpdate />
          <ModalEmailVerify />
          <ModalPhotoUpdate />
          <ModalPasswordUpdate />
          <ModalImageView />
          <ModalReportJob />
          <ModalReportProfile />
          <ModalLocationUpdate />
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
