// src/utils/firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase, set, ref, push, onValue, get } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyACDxxK0YgRpEAEmQiImOcJwgWJRfYyNH0",
  authDomain: "wired-plateau-435622-v5.firebaseapp.com",
  databaseURL: "https://wired-plateau-435622-v5-default-rtdb.firebaseio.com",
  projectId: "wired-plateau-435622-v5",
  storageBucket: "wired-plateau-435622-v5.firebasestorage.app",
  messagingSenderId: "674549090834",
  appId: "1:674549090834:web:dc3d3f93b7e98f9b6ae6cb",
  measurementId: "G-E8FH9YKPW7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, set, ref, push, onValue, get };
