import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import network from "../../../constants/Network";
import Cookies from 'js-cookie';
import { includes } from "lodash";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalPasswordUpdate = (props) => {
  const gContext = useContext(GlobalContext);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPass, setShowPass] = useState([]);
  const { user } = useContext(GlobalContext);
  const [user_id, setUser_id] = useState('');

  useEffect(() => {
    if (user && user.id) {
      setUser_id(user.id)
    }
  }, [user]);

  const togglePassword = (index) => {
    if (showPass.includes(index)) {
      setShowPass(showPass.filter((item) => item !== index));
    } else {
      setShowPass([...showPass, index]);
    }
  };

  const handleClose = () => {
    console.log("Resetting form data and closing modal");
    setErrors({});
    gContext.togglePasswordUpdateModal();
    setOldPassword('');
    setPassword('');
    setPasswordConfirmation('');
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    console.log('Handle password submit pressed');

    setErrors({});

    if (!user_id) {
      setErrors(prevErrors => ({
        ...prevErrors,
        passwordUpdateError: "Please log in again",
      }));
      return;
    }

    let isValid = true;

    if (!oldPassword) {
      setErrors(prevErrors => ({
        ...prevErrors,
        old_password: 'Old password is required'
      }));
      isValid = false;
    }

    if (!password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'New password is required'
      }));
      isValid = false;
    }

    if (password !== passwordConfirmation) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password_confirmation: 'Password confirmation mismatch'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    try {

      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('old_password', oldPassword);
      formData.append('password', password);
      formData.append('password_confirmation', passwordConfirmation);

      const response = await axios.post(`${network.serverip}/api/password-edit-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

      console.log('This is the response:', response);

      if (response.data.success) {
        handleSuccessMessage(response.data.message);
        setErrors({});
        setOldPassword('');
        setPassword('');
        setPasswordConfirmation('');
        gContext.togglePasswordUpdateModal();
      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while updating password:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            passwordUpdateError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          passwordUpdateError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const styles = ModalPasswordUpdateStyling();

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.passwordUpdateModalVisible}
      onHide={gContext.togglePasswordUpdateModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white rounded-8 overflow-hidden">
          <div className="row no-gutters">
            <div className="col-lg-5 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                  <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Need to Update Your Password?
                  </h3>
                  <p className="mb-0 font-size-4 text-white">
                    Follow the steps to update your password.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="bg-white-2 h-100 px-11 pt-11 pb-7">

                <form onSubmit={handlePasswordSubmit}>

                  <div className="form-group">
                    <label
                      htmlFor="old_password"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Old Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPass.includes(1) ? "text" : "password"}
                        autoComplete="off"
                        className="form-control"
                        id="old_password"
                        name="old_password"
                        placeholder="Enter old password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                      <a
                        href="/#"
                        className="pos-abs-cr mr-6 text-black-2"
                        onClick={(e) => {
                          e.preventDefault();
                          togglePassword(1);
                        }}
                      >
                        <i className={`fas ${showPass.includes(1) ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </a>
                    </div>
                    {errors?.old_password ? <p className="font-size-3 text-danger">
                      {errors.old_password}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      New Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPass.includes(2) ? "text" : "password"}
                        autoComplete="off"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <a
                        href="/#"
                        className="pos-abs-cr mr-6 text-black-2"
                        onClick={(e) => {
                          e.preventDefault();
                          togglePassword(2);
                        }}
                      >
                        <i className={`fas ${showPass.includes(2) ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </a>
                    </div>
                    {errors?.password ? <p className="font-size-3 text-danger">
                      {errors.password}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="password_confirmation"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Confirm Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPass.includes(3) ? "text" : "password"}
                        autoComplete="off"
                        className="form-control"
                        id="password_confirmation"
                        name="password_confirmation"
                        placeholder="Confirm new password"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                      />
                      <a
                        href="/#"
                        className="pos-abs-cr mr-6 text-black-2"
                        onClick={(e) => {
                          e.preventDefault();
                          togglePassword(3);
                        }}
                      >
                        <i className={`fas ${showPass.includes(3) ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </a>
                    </div>
                    {errors?.password_confirmation ? <p className="font-size-3 text-danger">
                      {errors.password_confirmation}
                    </p> : null}
                  </div>

                  <div className="form-group mb-8">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                          Submit
                        </button>
                      </div>
                      <div className="col-12 d-flex justify-content-center">
                        {errors?.passwordUpdateError ? (
                          <p className="font-size-3 text-danger text-center">
                            {errors.passwordUpdateError}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalPasswordUpdate;

function ModalPasswordUpdateStyling() {
  const styles = {
    btnAsText: {
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      color: 'inherit',
      // font: 'inherit',
      cursor: 'pointer',
    }
  }
  return styles;
}