// src/axiosConfig.js
import axios from 'axios';
import network from '../constants/Network';

// const baseURL = typeof window !== 'undefined' 
//   ? (window.location.hostname.includes('localhost') 
//       ? 'http://localhost:8000/api' 
//       : 'https://devadmin.stackle.in/api') 
//   : 'http://localhost:8000/api';
  
// const baseURL = 'https://devadmin.stackle.in/api';
const baseURL = `${network.serverip}/api`;
const apiClient = axios.create({
  baseURL: baseURL,
  // You can add more default configurations here, like headers, timeouts, etc.
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;




// // below is the code to use token
// import axios from 'axios';
// import network from '../constants/Network';
// import Cookies from 'js-cookie';

// // Set the base URL for your API
// const baseURL = `${network.serverip}/api`;

// // Create the Axios instance
// const apiClient = axios.create({
//   baseURL: baseURL,
//   // Add default headers (for JSON requests)
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// // Add the request interceptor to include the token in the headers
// apiClient.interceptors.request.use(
//   (config) => {
//     // Retrieve the token from wherever it is stored (e.g., localStorage)
//     const token = Cookies.get('token');  // Adjust this line based on your token storage method
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`;  // Add token to the Authorization header
//     }
//     return config;  // Return the modified config
//   },
//   (error) => {
//     return Promise.reject(error);  // Handle any errors that occur before sending the request
//   }
// );

// export default apiClient;  // Export the configured Axios instance