import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import apiClient from '../../axiosConfig';
import Cookies from 'js-cookie';
import axios from "axios";
import network from "../../../constants/Network";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalEmailVerify = (props) => {
  const gContext = useContext(GlobalContext);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [verifyForm, setVerifyForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (gContext.user?.email) {
      setEmail(gContext.user.email);
    }
  }, [gContext.user?.email]);

  const handleClose = () => {
    console.log("Resetting form data and closing modal");
    setErrors({});
    gContext.toggleEmailVerifyModal();
    setOtp('');
    setVerifyForm(false);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    console.log('Handle email submit pressed');

    setErrors({});

    let isValid = true;

    if (!email) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Email is required'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the email:', email);

    try {
      const response = await axios.post(`${network.serverip}/api/verify-email-web`, {
        email: email,
      });

      console.log('This is the response:', response);

      if (response.data.message) {
        handleSuccessMessage(response.data.message);
        setErrors({});
        setVerifyForm(true);
      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while generating OTP:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            otpGenerateError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          otpGenerateError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    console.log('Handle otp submit pressed');

    setErrors({});

    let isValid = true;

    if (!email) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Email is required'
      }));
      isValid = false;
    }

    if (!otp || otp.length !== 6) {
      setErrors(prevErrors => ({
        ...prevErrors,
        otp: 'Please enter a valid 6-digit OTP'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the email & otp:', email, otp);

    try {
      const response = await axios.post(`${network.serverip}/api/confirm-verify-email-otp-web`, {
        email: email,
        otp: otp,
      });

      console.log('This is the response:', response);

      if (response.data.message) {
        setErrors({});
        setVerifyForm(false);
        setOtp('');
        handleSuccessMessage(response.data.message);
        gContext.toggleEmailVerifyModal();

        gContext.setUser(prev => ({
          ...prev,
          is_email_verified: 1
        }));

        let user = JSON.parse(Cookies.get('user'));
        user.is_email_verified = 1;
        Cookies.set('user', JSON.stringify(user), { expires: 30 });

      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while verifying OTP:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            otpVerifyError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          otpVerifyError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();

    console.log('Handle otp resend pressed');

    setErrors({});

    let isValid = true;

    if (!email) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Email is required'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the email resend otp:', email);

    try {
      const response = await axios.post(`${network.serverip}/api/resend-email-verify-otp-web`, {
        email: email,
      });

      console.log('This is the response:', response);

      if (response.data.message) {
        setErrors({});
        setOtp('');
        handleSuccessMessage(response.data.message);
      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while resending OTP:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            otpVerifyError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          otpVerifyError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const styles = ModalEmailVerifyStyling();

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.emailVerifyModalVisible}
      onHide={gContext.toggleEmailVerifyModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white rounded-8 overflow-hidden">
          <div className="row no-gutters">
            <div className="col-lg-5 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                  <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Welcome Back
                  </h3>
                  <p className="mb-0 font-size-4 text-white">
                    To verify your email, please follow the steps.
                  </p>
                </div>
                <div className="border-top border-default-color-2 mt-auto">
                  <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{gContext.newJobsToday}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New jobs posted today
                      </p>
                    </div>
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{gContext.newCompaniesToday}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New companies registered
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="bg-white-2 h-100 px-11 pt-11 pb-7">

                {!verifyForm ? (
                  <form onSubmit={handleEmailSubmit}>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email"
                        id="email"
                        name="email"
                        value={email}
                        readOnly
                      />
                      {errors?.email ? <p className="font-size-3 text-danger">
                        {errors.email}
                      </p> : null}
                    </div>

                    <div className="form-group mb-8">
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                            Next
                          </button>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          {errors?.otpGenerateError ? (
                            <p className="font-size-3 text-danger text-center">
                              {errors.otpGenerateError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </form>


                ) : (

                  <form onSubmit={handleOtpSubmit}>
                    <div className="form-group">

                      <div className="w-100 d-flex justify-content-center">
                        <p className="font-size-4 text-success min-height-40">
                          {successMessage || ''}
                        </p>
                      </div>

                      <label
                        htmlFor="email"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email"
                        id="email"
                        name="email"
                        value={email}
                        readOnly
                      />
                      {errors?.email ? <p className="font-size-3 text-danger">
                        {errors.email}
                      </p> : null}
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="otp"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Enter 6 digit OTP sent to your email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter otp"
                        id="otp"
                        name="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      {errors?.otp ? <p className="font-size-3 text-danger">
                        {errors.otp}
                      </p> : null}
                    </div>

                    <div className="form-group mb-8">
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                            Verify
                          </button>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          {errors?.otpVerifyError ? (
                            <p className="font-size-3 text-danger text-center">
                              {errors.otpVerifyError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-4">
                      <p className="font-size-3 text-black mb-0">
                        Didn't receive OTP Code!
                      </p>
                      <button
                        type="button"
                        className="font-size-3 font-weight-bold text-dodger text-center ml-2"
                        style={{ ...styles.btnAsText, textDecoration: 'underline' }}
                        onClick={handleResendOtp}
                      >
                        Resend
                      </button>
                    </div>
                  </form>
                )}

              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalEmailVerify;

function ModalEmailVerifyStyling() {
  const styles = {
    btnAsText: {
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      color: 'inherit',
      // font: 'inherit',
      cursor: 'pointer',
    }
  }
  return styles;
}