import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import network from "../../../constants/Network";
import axios from "axios";
import Cookies from 'js-cookie';

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalPhotoUpdate = (props) => {

  const gContext = useContext(GlobalContext);
  const imageBaseUrl = `${network.serverip}/images/`;

  const { user, profile } = useContext(GlobalContext);
  const [imageUrl, setImageUrl] = useState('');
  const [userId, setUserId] = useState('');
  const [errors, setErrors] = useState('');

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    await handlePhotoSubmit(file);
  };

  const handlePhotoSubmit = async (file) => {

    console.log('Handle photo submit pressed');

    if (!userId) {
      return;
    }

    setErrors('');

    try {

      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('image', file);

      const response = await axios.post(`${network.serverip}/api/profile-photo-edit-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

      console.log('This is the response:', response);

      if (response.data.success) {
        setImageUrl(URL.createObjectURL(file));
        const { profile } = response.data;
        Cookies.set('profile', JSON.stringify(profile), { expires: 30 });
        gContext.setProfile(profile);
      }

    } catch (error) {
      console.log('This is the update photo catch response:', error.response);

      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            updateError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          updateError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handlePhotoDelete = async () => {

    console.log('Handle photo delete pressed');

    if (!userId) {
      return;
    }

    setErrors('');

    try {

      const response = await axios.delete(`${network.serverip}/api/profile-photo-delete-web`, {
        params: { user_id: userId },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('This is the response:', response);

      if (response.data.success) {
        setImageUrl('');
        const { profile } = response.data;
        Cookies.set('profile', JSON.stringify(profile), { expires: 30 });
        gContext.setProfile(profile);
      }

    } catch (error) {
      console.log('This is the delete photo catch response:', error.response);

      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            updateError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          updateError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handleClose = () => {
    gContext.togglePhotoUpdateModal();
  };

  const styles = ModalProfilePhotoStyling();

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    if (profile && profile.image) {
      setImageUrl(imageBaseUrl + profile.image);
    }
  }, [profile]);

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.photoUpdateModalVisible}
      onHide={gContext.togglePhotoUpdateModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="d-flex justify-content-center align-items-center" style={styles.modalContainer}>
          <div className="row">
            <div className="col-12 mb-5">
              <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                Edit Profile Photo
              </h4>
            </div>
            <div className="col-12">
              <img
                src={imageUrl ?
                  imageUrl :
                  imageBaseUrl + "profile_default_photo.png"}
                alt="Modal Display"
                style={styles.image}
              />
              {errors?.image ? <p className="font-size-3 text-danger">
                {errors.image}
              </p> : null}
              {imageUrl ? (
                <div className="w-100 d-flex flex-column flex-sm-row justify-content-center mt-5">
                  <input
                    type="file"
                    id="imageUpload"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <button
                    type="button"
                    onClick={() => document.getElementById("imageUpload").click()}
                    className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5 mx-5"
                  >
                    <i className="fas fa-camera mr-2"></i>
                    Upload another photo
                  </button>
                  <button
                    onClick={handlePhotoDelete}
                    type="button"
                    className="btn btn-outline-danger h-px-48 rounded-3 mb-5 px-5 mx-5">
                    <i className="fas fa-trash mr-2"></i>
                    Remove profile photo
                  </button>
                </div>
              ) : (
                <div className="w-100 d-flex flex-column flex-sm-row justify-content-center mt-5">
                  <input
                    type="file"
                    id="imageUpload"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <button
                    type="button"
                    onClick={() => document.getElementById("imageUpload").click()}
                    className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5 mx-5"
                  >
                    <i className="fas fa-camera mr-2"></i>
                    Upload profile photo
                  </button>
                </div>
              )}
              {errors?.updateError ? <p className="font-size-3 text-danger">
                {errors.updateError}
              </p> : null}
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalPhotoUpdate;

function ModalProfilePhotoStyling() {
  const styles = {
    modalContainer: {
      padding: '20px',
      textAlign: 'center',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '50vh',
      objectFit: 'contain',
    },
  }
  return styles;
}