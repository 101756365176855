// import React, { useContext } from "react";
// import { Link } from "gatsby";
// import { Collapse } from "react-bootstrap";
// import GlobalContext from "../../context/GlobalContext";
// import imgL from "../../assets/image/logo-main-black.png";

// const Sidebar = () => {
//   const gContext = useContext(GlobalContext);

//   return (
//     <>
//       <Collapse in={gContext.showSidebarDashboard}>
//         <div className="dashboard-sidebar-wrapper pt-11" id="sidebar">
//           <div className="brand-logo px-11">
//             <Link to="/">
//               <img src={imgL} alt="" />
//             </Link>
//           </div>
//           <div className="my-15 px-11">
//             <Link
//               to="/create-job"
//               className="btn btn-primary btn-xl w-100 text-uppercase"
//             >
//               <span className="mr-5 d-inline-block">+</span>Post a new job
//             </Link>
//           </div>
//           <ul className="list-unstyled dashboard-layout-sidebar">
//             <li className="">
//               <Link
//                 activeClassName="active"
//                 to="/dashboard-main"
//                 className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
//               >
//                 <i className="icon icon-layout-11 mr-7"></i>Dashboard
//               </Link>
//             </li>
//             <li className="">
//               <Link
//                 to="/dashboard-jobs"
//                 activeClassName="active"
//                 className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
//               >
//                 <i className="fas fa-briefcase mr-7"></i>Posted Jobs
//               </Link>
//             </li>
//             <li className="">
//               <Link
//                 to="/dashboard-applicants"
//                 activeClassName="active"
//                 className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
//               >
//                 <i className="fas fa-user mr-7"></i>Applicants{" "}
//                 <span className="ml-auto px-1 h-1 bg-dodger text-white font-size-3 rounded-5 max-height-px-18 flex-all-center">
//                   14
//                 </span>
//               </Link>
//             </li>
//             <li className="">
//               <Link
//                 to="/dashboard-settings"
//                 activeClassName="active"
//                 className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
//               >
//                 <i className="fas fa-cog mr-7"></i>Settings
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </Collapse>
//       <a
//         href="/#"
//         className="sidebar-mobile-button"
//         onClick={(e) => {
//           e.preventDefault();
//           gContext.toggleSidebarDashboard();
//         }}
//       >
//         <i className="icon icon-sidebar-2"></i>
//       </a>
//     </>
//   );
// };

// export default Sidebar;


import React, { useContext } from "react";
import { Link } from "gatsby";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import imgL from "../../assets/image/logo-main-black.png";
import Logo from "../../components/Logo";

const Sidebar = () => {
  const gContext = useContext(GlobalContext);

  const caseKey = !gContext.token
    ? "unauthenticated"
    : gContext.isJobProvider === 1
      ? "jobProvider"
      : "jobSeeker";

  let content;

  switch (caseKey) {
    case "jobProvider":
      content = (
        <>
          <div className="my-15 px-11">
            <Link
              to="/dashboard-create-job"
              className="btn btn-primary btn-xl w-100 text-uppercase"
            >
              <span className="mr-5 d-inline-block">+</span>Post a new job
            </Link>
          </div>
          <ul className="list-unstyled dashboard-layout-sidebar">
            <li className="">
              <Link
                to="/dashboard-jobs"
                activeClassName="active"
                className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
              >
                <i className="fas fa-briefcase mr-7"></i>Posted Jobs & Applicants
              </Link>
            </li>
          </ul>
        </>
      );
      break;

    case "jobSeeker":
      content = (
        <>
          <div className="d-block" style={{ height: '5rem' }}></div>
          <ul className="list-unstyled dashboard-layout-sidebar mt-15">
            <li className="">
              <Link
                to="/dashboard-applied-jobs"
                activeClassName="active"
                className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
              >
                <i className="fas fa-briefcase mr-7"></i>Applied Jobs
              </Link>
            </li>
          </ul>
        </>
      );
      break;

    case "unauthenticated":
    default:
      content = (
        <>
          <div className="d-block" style={{ height: '5rem' }}></div>
          <ul className="list-unstyled dashboard-layout-sidebar mt-15">
            <li>
              <Link
                to="/"
                activeClassName="active"
                className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center"
              >
                <i className="fas fa-home mr-7"></i>Home
              </Link>
            </li>
          </ul>
        </>
      );
      break;
  }

  return (
    <>
      <Collapse in={gContext.showSidebarDashboard}>
        <div className="dashboard-sidebar-wrapper pt-0" id="sidebar">

          <div className="brand-logo px-11 pb-7">
            <Link to="/">
              <Logo white={gContext.header.theme === "dark"} width="12rem" />
            </Link>
          </div>

          <div className="d-block d-sm-none" style={{ height: '3rem' }}></div>

          <div>{content}</div>


        </div>
      </Collapse>
      <a
        href="/#"
        className="sidebar-mobile-button"
        onClick={(e) => {
          e.preventDefault();
          gContext.toggleSidebarDashboard();
        }}
      >
        <i className="icon icon-sidebar-2"></i>
      </a>
    </>
  );
};

export default Sidebar;
