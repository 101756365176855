import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import ProfileSidebar from "../ProfileSidebar";
import network from "../../../constants/Network";
import axios from "axios";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalReportJob = (props) => {
  const imageInputRef = useRef(null);
  const isMountedRef = useRef(false);
  const gContext = useContext(GlobalContext);
  const { user, reportJobId } = useContext(GlobalContext);
  const imageBaseUrl = `${network.serverip}/images/`;
  const [isFocused, setIsFocused] = useState('');
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [formData, setFormData] = useState({
    user_id: "",
    description: "",
    type: "job",
    image: null,
    job_id: "",
    reported_by: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
      handleClose();
    }, 3000);
  };

  const handleClose = () => {
    gContext.toggleReportJobModal();
    setFormData({
      user_id: "",
      description: "",
      type: "Job",
      image: null,
      job_id: "",
      reported_by: "",
    });
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
    setImageUrl('');
    setErrors({});
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
    setImageUrl(URL.createObjectURL(file));
  };

  const handleImageDelete = () => {
    setImageUrl('');

    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      image: '',
    }));

  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    console.log('This is the formData', formData);

    setErrors({});

    let isValid = true;

    if (!formData.reported_by) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadError: 'Please log in to continue',
      }));
      isValid = false;
    }

    if (!formData.job_id) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadError: 'Something went wrong. Please try again',
      }));
      isValid = false;
    }

    if (!formData.description) {
      setErrors(prevErrors => ({
        ...prevErrors,
        description: 'Please describe the issue'
      }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {

      const response = await axios.post(
        `${network.serverip}/api/report-job-or-profile-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.success && isMountedRef.current) {
        console.log(`Job reported successfully:`, response.data);
        handleSuccessMessage(response.data.message);
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
      }

    } catch (error) {
      console.error('Error reporting job:', error);

      if (error.response) {

        if (error.response.data.error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.error,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

        return;

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));

        return;

      }
    }
  }

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);


  useEffect(() => {
    if (user && user.id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        reported_by: user.id,
      }));
    }

    if (reportJobId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        job_id: reportJobId,
      }));
    }

  }, [user, reportJobId]);

  const styles = ModalReportJobStyling();

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.reportJobModalVisible}
      onHide={gContext.toggleReportJobModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <form
          onSubmit={handleSubmit}
          className="bg-white rounded-8 overflow-hidden">
          <div className="p-10 w-100">
            <div className="d-flex justify-content-center border-bottom border-mercury pb-2">
              <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                Report any issue
              </h4>
            </div>
            <div className="w-100 border-bottom border-mercury mb-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="image"
                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                >
                  Upload Screenshot (Optional):
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  name="image"
                  onChange={handleImageChange}
                  id="image"
                  ref={imageInputRef}
                  onFocus={() => setIsFocused('image')}
                  onBlur={() => setIsFocused('')}
                  style={isFocused === 'image' ? styles.focussedInput : {}}
                />
                {errors?.image ? <p className="font-size-3 text-danger">
                  {errors.image}
                </p> : null}
                {imageUrl && (
                  <div className="d-flex justify-content-start mt-7">
                    <div style={styles.imageContainer}>
                      <img
                        src={imageUrl}
                        alt=""
                        className="img-fluid"
                        style={styles.imageStyle}
                      />
                      <button
                        type="button"
                        onClick={handleImageDelete}
                        style={styles.imageDeleteButton}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="form-group mt-9 w-100">
                <label
                  htmlFor="description"
                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                >
                  Description:
                </label>
                <textarea
                  className="form-control"
                  placeholder="Describe the issue..."
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={(e) => {
                    if (e.target.value.length <= 255) {
                      handleChange(e);
                      e.target.style.height = 'auto';
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }
                  }}
                  rows="4"
                  onFocus={() => setIsFocused('description')}
                  onBlur={() => setIsFocused('')}
                  style={{
                    ...isFocused === 'description' ? styles.focussedInput : {},
                    overflow: 'hidden',
                    resize: 'none'
                  }}
                  maxLength={255}
                />
                <p
                  className="font-size-4 text-dodger d-flex justify-content-end"
                >
                  {`${formData?.description?.length || 0}/${255}`}
                </p>
                {errors?.description ? <p className="font-size-3 text-danger">
                  {errors?.description}
                </p> : null}
              </div>

            </div>

            <div className="w-100 d-flex justify-content-end">
              <div className="w-100 d-flex flex-column flex-sm-row justify-content-end">
                <button
                  type="button"
                  onClick={handleClose}
                  className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5 mr-5">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-danger h-px-48 rounded-3 mb-5 px-5">
                  Report
                </button>
              </div>
            </div>

            {successMessage &&
              <div className="w-100 d-flex justify-content-center">
                <p className="font-size-4 text-success">
                  {successMessage}
                </p>
              </div>
            }

            <div className="w-100 d-flex justify-content-center">
              {errors?.uploadError ? <p className="font-size-3 text-danger">
                {errors?.uploadError}
              </p> : null}
            </div>

          </div>
        </form>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalReportJob;

function ModalReportJobStyling() {
  const styles = {
    imageContainer: {
      position: 'relative',
      display: 'inline-block'
    },

    imageStyle: {
      maxHeight: '200px',
      width: 'auto'
    },

    imageDeleteButton: {
      position: 'absolute',
      top: '-15px',
      right: '-15px',
      background: 'white',
      color: 'gray',
      borderRadius: '50%',
      border: 'none',
      width: '30px',
      height: '30px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    focussedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }
  }
  return styles;
}

