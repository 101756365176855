import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import network from "../../../constants/Network";
import Cookies from 'js-cookie';

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalEmailUpdate = (props) => {
  const gContext = useContext(GlobalContext);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const { user } = useContext(GlobalContext);
  const [user_id, setUser_id] = useState('');


  useEffect(() => {
    if (user && user.id) {
      setUser_id(user.id)
    }
  }, [user]);

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  const handleClose = () => {
    console.log("Resetting form data and closing modal");
    setErrors({});
    gContext.toggleEmailUpdateModal();
    setEmail('');
    setPassword('');
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    console.log('Handle email submit pressed');

    setErrors({});

    if (!user_id) {
      setErrors(prevErrors => ({
        ...prevErrors,
        emailUpdateError: "Please log in again",
      }));
      return;
    }

    let isValid = true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Email is required'
      }));
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: 'Enter a valid email address'
      }));
      isValid = false;
    }

    if (!password) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'Password is required'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the email update:', email, password);

    try {

      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('email', email);
      formData.append('password', password);

      const response = await axios.post(`${network.serverip}/api/email-edit-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

      console.log('This is the response:', response);

      if (response.data.success) {
        handleSuccessMessage(response.data.message);

        const { user: userData } = response.data;
        let user = JSON.parse(Cookies.get('user'));
        user.email = userData?.email;
        Cookies.set('user', JSON.stringify(user), { expires: 30 });
        gContext.setUser(prev => ({
          ...prev,
          email: userData?.email
        }));
        
        gContext.toggleEmailUpdateModal();

        setErrors({});
        setEmail('');
        setPassword('');

      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while updating email:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            emailUpdateError: error.response.data.message,
          }));
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          emailUpdateError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const styles = ModalEmailUpdateStyling();

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.emailUpdateModalVisible}
      onHide={gContext.toggleEmailUpdateModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white rounded-8 overflow-hidden">
          <div className="row no-gutters">
            <div className="col-lg-5 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                  <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Ready to Update Your Email?
                  </h3>
                  <p className="mb-0 font-size-4 text-white">
                    Follow the steps to update your email and stay connected.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="bg-white-2 h-100 px-11 pt-11 pb-7">

                <form onSubmit={handleEmailSubmit}>
                  <div className="form-group">

                    <div className="w-100 d-flex justify-content-center">
                      <p className="font-size-4 text-success min-height-40">
                        {successMessage || ''}
                      </p>
                    </div>

                    <label
                      htmlFor="email"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Enter new email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter new email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors?.email ? <p className="font-size-3 text-danger">
                      {errors.email}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPass ? "text" : "password"}
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <a
                        href="/#"
                        className="pos-abs-cr mr-6 text-black-2"
                        onClick={(e) => {
                          e.preventDefault();
                          togglePassword();
                        }}
                      >
                        <i className={`fas ${showPass ? 'fa-eye' : 'fa-eye-slash'}`} />
                      </a>
                    </div>
                    {errors?.password ? <p className="font-size-3 text-danger">
                      {errors.password}
                    </p> : null}
                  </div>

                  <div className="form-group mb-8">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                          Submit
                        </button>
                      </div>
                      <div className="col-12 d-flex justify-content-center">
                        {errors?.emailUpdateError ? (
                          <p className="font-size-3 text-danger text-center">
                            {errors.emailUpdateError}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalEmailUpdate;

function ModalEmailUpdateStyling() {
  const styles = {
    btnAsText: {
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      color: 'inherit',
      // font: 'inherit',
      cursor: 'pointer',
    }
  }
  return styles;
}