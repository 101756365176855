import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import apiClient from '../../axiosConfig';
import Cookies from 'js-cookie';
import axios from "axios";
import network from "../../../constants/Network";
import { useLoadScript, Autocomplete, GoogleMap, Circle, MarkerF } from '@react-google-maps/api';

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const libraries = ['places'];

const ModalLocationUpdate = () => {

  const gContext = useContext(GlobalContext);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const { user, coordinates } = useContext(GlobalContext);
  const [user_id, setUser_id] = useState('');

  // Google Map settings
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const [location, setLocation] = useState({});
  const [region, setRegion] = useState({});
  const [markerPosition, setMarkerPosition] = useState({});

  const [searchInput, setSearchInput] = useState('');

  const [zoom, setZoom] = useState(10);
  const autocompleteRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDPP-ap1mjWcD9jdPNG1bL197V66Xsgx_o",
    libraries,
  });

  // Function to handle when map location is changed
  const onMapChange = async (lat, lng) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const response = await geocoder.geocode({ location: { lat, lng } });

      if (response.results && response.results.length > 0) {
        const locationName = response.results[0].formatted_address;
        setLocation({ lat, lng, name: locationName });

        console.log('This is the location:', lat, lng, locationName);

      } else {
        console.log("No address found for the selected location.");
        setLocation({ lat, lng, name: "Unknown Location" });
      }

      setMarkerPosition({ lat, lng });
    } catch (error) {
      console.error("Geocoding failed: ", error);
      setLocation({ lat, lng, name: "Error Fetching Location Name" });
      setMarkerPosition({ lat, lng });
    }
  };

  // Function to handle when a place is selected via autocomplete
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      const { lat, lng } = place.geometry.location;
      const latitude = lat();
      const longitude = lng();
      const locationName = place.formatted_address || "Unknown Location";

      setLocation({ lat: latitude, lng: longitude, name: locationName });
      setRegion({ lat: latitude, lng: longitude, name: locationName });
      setMarkerPosition({ lat: latitude, lng: longitude });
      setZoom(15);
      setSearchInput(place.formatted_address);

      console.log('This is the location:', latitude, longitude, locationName);

    } else {
      console.log("No geometry data for the selected place.");
    }
  };

  const handleMapSave = async (e) => {
    e.preventDefault();

    console.log('Handle map save pressed');

    console.log('This is the location update:', location);

    setErrors({});

    if (!user_id) {
      setErrors(prevErrors => ({
        ...prevErrors,
        updateError: "Please log in again",
      }));
      return;
    }

    let isValid = true;

    if (!location?.lat || !location?.lng || !location?.name) {
      setErrors(prevErrors => ({
        ...prevErrors,
        updateError: 'Some of the fields are missing'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    console.log('This is the location update:', location);

    try {

      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('latitude', location?.lat || null);
      formData.append('longitude', location?.lng || null);
      formData.append('location', location?.name || '');

      const response = await axios.post(`${network.serverip}/api/user-location-update-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

      console.log('This is the response:', response);

      if (response.data.success) {
        handleSuccessMessage(response.data.message);

        const { user } = response.data;
        const existingUser = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {};
        const updatedUser = { ...existingUser, ...user };
        Cookies.set('user', JSON.stringify(updatedUser), { expires: 30 });
        gContext.setUser(updatedUser);

        gContext.toggleLocationUpdateModal();

        setErrors({});
      }

    } catch (error) {
      console.log('This is the catch response:', error.response);

      console.error('Error while updating location:', error);

      // Handle specific error responses from the backend
      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            updateError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          updateError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  const handleClose = () => {
    console.log("Closing modal");
    setErrors({});
    gContext.toggleLocationUpdateModal();

    const lat = parseFloat(gContext?.user?.latitude) || parseFloat(coordinates?.latitude) || 10.850516;
    const lng = parseFloat(gContext?.user?.longitude) || parseFloat(coordinates?.longitude) || 76.271080;

    setLocation({
      lat: lat,
      lng: lng,
      name: gContext?.user?.location || 'not available',
    });

    setRegion({
      lat: lat,
      lng: lng,
      name: gContext?.user?.location || 'not available',
    });

    setMarkerPosition({
      lat: lat,
      lng: lng,
    });
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  useEffect(() => {
    if (user && user.id) {
      setUser_id(user.id)
    }
  }, [user]);

  useEffect(() => {

    const lat = parseFloat(gContext?.user?.latitude) || parseFloat(coordinates?.latitude) || 10.850516;
    const lng = parseFloat(gContext?.user?.longitude) || parseFloat(coordinates?.longitude) || 76.271080;

    setLocation({
      lat: lat,
      lng: lng,
      name: gContext?.user?.location || 'not available',
    });

    setRegion({
      lat: lat,
      lng: lng,
      name: gContext?.user?.location || 'not available',
    });

    setMarkerPosition({
      lat: lat,
      lng: lng,
    });

  }, [gContext?.user?.latitude, gContext?.user?.longitude, gContext?.user?.location, coordinates]);

  const styles = ModalLocationUpdateStyling();

  return (
    <ModalStyled
      size="xl"
      centered
      show={gContext.locationUpdateModalVisible}
      onHide={gContext.toggleLocationUpdateModal}
      style={{ zIndex: 2000 }}
    >
      <Modal.Body style={{ position: 'relative', zIndex: 2000, width: '100%' }}>
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>

        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p>Select your location:</p>
          </div>

          {loadError ? (
            <div>Error loading maps</div>
          ) : !isLoaded ? (
            <div>Loading maps...</div>
          ) : (<div>
            <Autocomplete
              onLoad={(autocomplete) => {
                autocompleteRef.current = autocomplete;
              }}
              onPlaceChanged={handlePlaceChanged}
              style={{ position: 'relative', zIndex: 2000 }}
            >
              <input
                type="text"
                className="form-control"
                name="titleSearch"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                id="titleSearch"
                placeholder="Search location..."
                style={{ zIndex: 2000, position: 'relative', marginBottom: '5px' }}
              />
            </Autocomplete>

            {/* Custom styles for suggestion container */}
            <style>{`.pac-container {z-index: 3000;}`}</style>

            <GoogleMap
              center={region}
              zoom={zoom}
              mapContainerStyle={mapContainerStyle}
              onClick={(e) => onMapChange(e.latLng.lat(), e.latLng.lng())}
            >
              <MarkerF position={markerPosition} />
            </GoogleMap>
          </div>)}
        </div>

        <div className="w-100 d-flex justify-content-center">
          {errors?.updateError ? <p className="font-size-3 text-danger">
            {errors.updateError}
          </p> : null}
        </div>

        <div className="d-flex justify-content-end mt-5">
          <button
            className="btn btn-outline-mercury text-uppercase rounded-3 border border-danger mr-1"
            onClick={handleClose}
          >
            <span className="text-danger">Cancel</span>
          </button>
          <button
            className="btn btn-primary text-uppercase rounded-3 ml-1"
            onClick={handleMapSave}
          >
            <span className="text-white">Save</span>
          </button>
        </div>

      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalLocationUpdate;

function ModalLocationUpdateStyling() {
  const styles = {

  }
  return styles;
}